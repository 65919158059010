import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import MainReducer from "./reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, MainReducer);

const middlewares = [thunk];
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

let store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(...middlewares)
    // other store enhancers if any
  )
);
let persistor = persistStore(store);

export { store, persistor };

// import { createStore, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import MainReducer from "./reducer";
// import { composeWithDevTools } from "redux-devtools-extension";
// import logger from "redux-logger";

// const middlewares = [thunk];
// // if (__DEV__) {
// middlewares.push(logger);

// export const store = createStore(
//   MainReducer,
//   composeWithDevTools(
//     applyMiddleware(...middlewares)
//     // other store enhancers if any
//   )
// );
