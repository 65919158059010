import { CContainer } from "@coreui/react";
import React from "react";

const TheFooter = () => {
  return (
    <footer>
      <CContainer
        className=" d-flex justify-content-center align-items-center"
        style={{ height: 54 }}
      >
        <h1 style={{ fontSize: 15, fontFamily: "Zen Antique Soft" }}>
          <a
            href="https://phonologixtherapy.com/"
            style={{ color: "black" }}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Developed by Phonologix{" "}
          </a>
        </h1>

        {/* <a href="https://coreui.io" target="_blank" rel="noopener noreferrer">CoreUI</a>
        <span className="ml-1">&copy; 2020 creativeLabs.</span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1">Powered by</span>
        <a href="https://coreui.io/react" target="_blank" rel="noopener noreferrer">CoreUI for React</a> */}
      </CContainer>
    </footer>
  );
};

export default React.memo(TheFooter);
