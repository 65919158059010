import React from "react";
import { CSelect, CLabel } from "@coreui/react";

const Select = ({
  id = "",
  title = "",
  options = [],
  valueAccessor = "value",
  keyAccessor = "key",
  value = "",
  error,
  required,
  onChange = () => {},
  onKeyDown = () => {},
  disabled = false,
  sideText,
  className,
  style,
  isEditable = true,
  labelClassName,
  noTitle = false,
  selectClassname = "",
  height,
  titleStyle,
}) => {
  return (
    <div className="d-flex flex-column">
      {title ? (
        <CLabel
          htmlFor={id}
          className={labelClassName}
          style={{ ...titleStyle }}
        >
          {title}
          {required ? <span style={{ color: "red" }}> *</span> : null}
        </CLabel>
      ) : null}
      <div
        style={{
          flex: 1,
          position: title || noTitle ? null : "absolute",
          bottom: title || noTitle ? null : 1,
          ...style,
        }}
      >
        {isEditable ? (
          <div
            className={`d-flex align-items-center ${
              className ? className : ""
            }`}
          >
            <CSelect
              disabled={disabled}
              custom
              name={"select"}
              id={id}
              value={value}
              style={{
                borderColor: error ? "#e55353" : "",
                // flex: sideText ? 1 : null,
                height: noTitle ? null : !height && 52,
                // paddingBlock: 25,
              }}
              onChange={onChange}
              className={selectClassname}
              // onKeyDown= {onKeyDown}
            >
              {options?.map((item, index) => (
                <option value={item[valueAccessor]} onChange={onKeyDown}>
                  {item[keyAccessor]}{" "}
                  {item?.count || item?.count === 0 ? `(${item?.count})` : ""}
                </option>
              ))}
            </CSelect>
            {sideText ? (
              <div style={{ width: "fit-content", marginLeft: 4 }}>
                {sideText}
              </div>
            ) : null}
          </div>
        ) : (
          <div style={{ fontSize: 16.5, fontWeight: 500 }}>
            {options?.find((item) => item?.value === value)?.key || "-"}
          </div>
        )}
        {error ? (
          <CLabel htmlFor={id} style={{ color: "#e55353", marginBottom: 0 }}>
            {error}
          </CLabel>
        ) : null}
      </div>
    </div>
  );
};

export default Select;
