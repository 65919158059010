import React from "react";
import RemarksList from "src/pages/leads/remarks/list/index";
import AddRemark from "src/pages/leads/remarks/add/index";
const Dashboard = React.lazy(() => import("../views/dashboard/Dashboard"));
const DashboardPlanExpired = React.lazy(() =>
  import("../views/dashboard/PlanExpired/index")
);
const DashboardSlotsBooked = React.lazy(() =>
  import("../views/dashboard/SlotBooked/index")
);
const DashboardSlotsCompleted = React.lazy(() =>
  import("../views/dashboard/SlotCompleted/index")
);
const DashboardAssessments = React.lazy(() =>
  import("../views/dashboard/AssessmentsReport/index")
);
const CreatePaymentsLink = React.lazy(() => import("../pages/paymentsLink"));
const GetPaymentsLinks = React.lazy(() => import("../pages/paymentsLink/list"));
const Transactions = React.lazy(() => import("../pages/transactions"));
const DemoGoalsRequests = React.lazy(() =>
  import("../pages/DemoGoalsRequests")
);
const AddSlots = React.lazy(() => import("../pages/slots/add/index"));
const UpdateSlots = React.lazy(() => import("../pages/slots/update/index"));
const getSlots = React.lazy(() => import("../pages/slots/list/index"));
const SlotDetails = React.lazy(() => import("../pages/slots/details/index"));
const MasterGames = React.lazy(() => import("../pages/masterGames"));
const DemoGame = React.lazy(() => import("../pages/DemoGame/list/index"));

const UsersAdd = React.lazy(() => import("../pages/users/add/index"));
const UsersList = React.lazy(() => import("../pages/users/list/index"));
const LeadsAdd = React.lazy(() => import("../pages/leads/add/index"));
const LeadsList = React.lazy(() => import("../pages/leads/list/index"));
const TherapistAdd = React.lazy(() => import("../pages/therapists/add/index"));
const TherapistList = React.lazy(() =>
  import("../pages/therapists/list/index")
);
const PartnerAdd = React.lazy(() => import("../pages/partners/add/index"));
const PartnersList = React.lazy(() => import("../pages/partners/list/index"));
const SaleAdd = React.lazy(() => import("../pages/sales/add/index"));
const SalesList = React.lazy(() => import("../pages/sales/list/index"));
const OperationAdd = React.lazy(() => import("../pages/operations/add/index"));
const OperationList = React.lazy(() =>
  import("../pages/operations/list/index")
);
const InvoicesList = React.lazy(() => import("../pages/invoices/list/index"));
const ListPlans = React.lazy(() => import("../pages/plans/list"));
const AddPlans = React.lazy(() => import("../pages/plans/add"));
const MasterGoal = React.lazy(() => import("../pages/masterGoals/list"));
const AddMasterGoal = React.lazy(() => import("../pages/masterGoals/add"));

const AddUserGoals = React.lazy(() => import("../pages/UserGoals/add"));
const ListUserGoals = React.lazy(() => import("../pages/UserGoals/list"));
const ListMasterActivity = React.lazy(() =>
  import("../pages/masterActivity/list")
);
const AddMasterActivity = React.lazy(() =>
  import("../pages/masterActivity/add")
);

const TutorialList = React.lazy(() => import("../pages/tutorials/list"));

const AddTutorial = React.lazy(() => import("../pages/tutorial/add"));
const ListTutorials = React.lazy(() => import("../pages/tutorial/list"));
const AddMasterForm = React.lazy(() => import("../pages/masterQuestions/add"));
const MasterFormList = React.lazy(() =>
  import("../pages/masterQuestions/list")
);
const UsersGoal = React.lazy(() => import("../pages/UserGoals/list"));
const ListUserActivity = React.lazy(() =>
  import("../pages/masterActivity/list")
);
const AddMasterVideo = React.lazy(() => import("../pages/masterVideos/add"));
const ListMasterVideo = React.lazy(() => import("../pages/masterVideos/list"));
const AllAppointments = React.lazy(() => import("../pages/allAppointments"));
const AllSlotDetails = React.lazy(() => import("../pages/allSlotDetails"));
const AddMedia = React.lazy(() => import("../pages/mediaManager/add"));
const MediaFilesUsage = React.lazy(() =>
  import("../pages/mediaManager/mediaFilesUsage")
);
const ListMedia = React.lazy(() => import("../pages/mediaManager/list"));
const Download = React.lazy(() => import("../pages/Download/index"));
const ScreeningTestsResponses = React.lazy(() =>
  import("../pages/screeningTest/list/index")
);
const ZoomReport = React.lazy(() =>
  import("../views/dashboard/ZoomReport/index")
);
const ScreeningTestReport = React.lazy(() =>
  import("../pages/screeningTest/list/report")
);
const LogsList = React.lazy(() => import("../pages/logs/list/index"));
const CalendarView = React.lazy(() =>
  import("../views/dashboard/CalendarView/index")
);

const DiscountCouponsList = React.lazy(() =>
  import("../pages/discountCoupons/list/index")
);
const AddDiscountCoupon = React.lazy(() =>
  import("../pages/discountCoupons/add/index")
);

const BankTransferList = React.lazy(() =>
  import("../pages/bankTransfer/index")
);
const PlanDashboard = React.lazy(() =>
  import("../pages/clientSide/PlanDashboard/PlanCard")
);

const operationRoutes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/logs/list", name: "Logs", component: LogsList },

  {
    path: "/plan/view",
    exact: true,
    name: "Plan View",
    component: PlanDashboard,
  },
  {
    path: "/planExpiredReport",
    name: "Plan Expire Report",
    exact: true,
    component: DashboardPlanExpired,
  },
  {
    path: "/slotBookReport",
    name: "Slot Booked Report",
    exact: true,
    component: DashboardSlotsBooked,
  },
  {
    path: "/slotCompleteReport",
    name: "Slot Completed Report",
    exact: true,
    component: DashboardSlotsCompleted,
  },
  {
    path: "/assessmentsReport",
    name: "Assessments Report",
    exact: true,
    component: DashboardAssessments,
  },

  {
    path: "/allAppointments",
    name: "Assessments",
    component: AllAppointments,
  },
  {
    path: "/demogoals/add",
    name: "Demo - Goals",
    component: DemoGame,
    exact: true,
  },
  {
    path: "/allSlotDetails/:slot_id",
    name: "All Slot Details",
    component: AllSlotDetails,
  },

  {
    path: "/users/list",
    exact: true,
    name: "Therapists List",
    component: TherapistList,
  },
  {
    path: "/users/add",
    exact: true,
    name: "Add Therapist",
    component: TherapistAdd,
  },
  {
    path: "/partners/list",
    exact: true,
    name: "Partners List",
    component: PartnersList,
  },
  {
    path: "/partners/add",
    exact: true,
    name: "Add Partner",
    component: PartnerAdd,
  },

  // ncbjhdsjhds

  {
    path: "/sales/view/:_id",
    exact: true,
    name: "View Sale",
    component: SaleAdd,
  },
  {
    path: "/sales/list",
    exact: true,
    name: "Sales List",
    component: SalesList,
  },
  {
    path: "/sales/add",
    exact: true,
    name: "Add Sale",
    component: SaleAdd,
  },
  {
    path: "/operation/view/:_id",
    exact: true,
    name: "View Operation",
    component: OperationAdd,
  },
  {
    path: "/operation/list",
    exact: true,
    name: "Operation List",
    component: OperationList,
  },
  {
    path: "/operation/add",
    exact: true,
    name: "Add Operation",
    component: OperationAdd,
  },

  {
    path: "/masterQuestions/add",
    exact: true,
    name: "Add Question",
    component: AddMasterForm,
  },
  {
    path: "/masterQuestions/edit/:id",
    exact: true,
    name: "Edit Question",
    component: AddMasterForm,
  },
  {
    path: "/masterQuestions/list",
    exact: true,
    name: "Questions List",
    component: MasterFormList,
  },
  {
    path: "/users/view/:_id",
    exact: true,
    name: "View Therapist",
    component: TherapistAdd,
  },
  {
    path: "/partners/view/:_id",
    exact: true,
    name: "View Partner",
    component: PartnerAdd,
  },
  {
    path: "/clients/list",
    exact: true,
    name: "Clients List",
    component: UsersList,
  },
  {
    path: "/clients/view/:_id",
    exact: true,
    name: "View Client",
    component: UsersAdd,
  },

  {
    path: "/clients/add/:appointment_id",
    exact: true,
    name: "Add Client",
    component: UsersAdd,
  },
  {
    path: "/clients/add",
    exact: true,
    name: "Add Client",
    component: UsersAdd,
  },

  // jnjasscbjsa
  {
    path: "/plans/list",
    exact: true,
    name: "Plans List",
    component: ListPlans,
  },
  {
    path: "/plans/add",
    exact: true,
    name: "Add Plans",
    component: AddPlans,
  },
  {
    path: "/plans/view/:_id",
    exact: true,
    name: "View Plans",
    component: AddPlans,
  },
  // njasnksjadsa

  {
    path: "/leads/list",
    exact: true,
    name: "Leads List",
    component: LeadsList,
  },
  {
    path: "/invoices/list",
    exact: true,
    name: "Invoices List",
    component: InvoicesList,
  },
  {
    path: "/remarks/list/:leadId",
    exact: true,
    name: "Remarks List",
    component: RemarksList,
  },
  {
    path: "/discountCoupons/list",
    exact: true,
    name: "Discount Coupons List",
    component: DiscountCouponsList,
  },
  {
    path: "/discountCoupons/add",
    exact: true,
    name: "Add Discount Coupon",
    component: AddDiscountCoupon,
  },
  {
    path: "/bankTransfer",
    exact: true,
    name: "Bank Transfer Approvals",
    component: BankTransferList,
  },
  {
    path: "/discountCoupons/view/:id",
    exact: true,
    name: "View Coupon",
    component: AddDiscountCoupon,
  },
  {
    path: "/remarks/add/:leadId",
    exact: true,
    name: "Add Remark",
    component: AddRemark,
  },
  {
    path: "/leads/view/:_id",
    exact: true,
    name: "View Lead",
    component: LeadsAdd,
  },
  {
    path: "/leads/add",
    exact: true,
    name: "Add Lead",
    component: LeadsAdd,
  },
  {
    path: "/payments/createLink",
    exact: true,
    name: "Create Payments Link",
    component: CreatePaymentsLink,
  },
  {
    path: "/payments/links",
    exact: true,
    name: "List Payments",
    component: GetPaymentsLinks,
  },
  {
    path: "/transactions",
    exact: true,
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/DemoGoalsRequests",
    exact: true,
    name: "Demo Goals Requests",
    component: DemoGoalsRequests,
  },

  {
    path: "/Slots/Add",
    exact: true,
    name: "Add Slot",
    component: AddSlots,
  },
  {
    path: "/Slots/edit/:id",
    exact: true,
    name: "Edit Slot",
    component: UpdateSlots,
  },
  {
    path: "/Slots/list",
    exact: true,
    name: "Slots List",
    component: getSlots,
  },
  {
    path: "/Slots/details/:slot_id/:booking_id?",
    exact: true,
    name: "Slot Details",
    component: SlotDetails,
  },
  {
    path: "/master-games",
    exact: true,
    name: "Master Games",
    component: MasterGames,
  },
  {
    path: "/masterActivity/list",
    exact: true,
    name: "View Master Activity",
    component: ListMasterActivity,
  },
  {
    path: "/masterActivity/add",
    exact: true,
    name: "Add Master Activity",
    component: AddMasterActivity,
  },
  {
    path: "/masterActivity/edit/:id",
    exact: true,
    name: "Edit Master Activity",
    component: AddMasterActivity,
  },
  {
    path: "/masterGoal/list",
    exact: true,
    name: "View Master Goal",
    component: MasterGoal,
  },

  {
    path: "/masterGoal/add",
    exact: true,
    name: "Add Master Goal",
    component: AddMasterGoal,
  },
  {
    path: "/masterGoal/edit/:id",
    exact: true,
    name: "Edit Master Goal",
    component: AddMasterGoal,
  },

  {
    path: "/UserGoals/add/:user_id",
    exact: true,
    name: "Add User Goals",
    component: AddUserGoals,
  },
  {
    path: "/UserGoals/add/:user_id",
    exact: true,
    name: "List User Goals",
    component: ListUserGoals,
  },
  {
    path: "/tutorials/add",
    exact: true,
    name: "Add Tutorial",
    component: AddTutorial,
  },
  {
    path: "/tutorials",
    exact: true,
    name: "Tutorials List",
    component: TutorialList,
  },
  {
    path: "/tutorials/edit/:id",
    exact: true,
    name: "Edit Tutorial",
    component: AddTutorial,
  },

  {
    path: "/masterVideo/add",
    exact: true,
    name: "Add MasterVideo",
    component: AddMasterVideo,
  },
  {
    path: "/masterVideo",
    exact: true,
    name: "MasterVideo List",
    component: ListMasterVideo,
  },
  {
    path: "/masterVideo/edit/:id",
    exact: true,
    name: "Edit MasterVideo",
    component: AddMasterVideo,
  },
  {
    path: "/tutorials/list",
    exact: true,
    name: "List Tutorials",
    component: ListTutorials,
  },
  {
    path: "/my_goals/:user_id",
    exact: true,
    name: "My Goals",
    component: UsersGoal,
  },
  {
    path: "/my_activities/:user_goal_id",
    exact: true,
    name: "My Activities",
    component: ListUserActivity,
  },
  {
    path: "/media-manager/add",
    exact: true,
    name: "Add Media",
    component: AddMedia,
  },
  {
    path: "/media-manager/list",
    exact: true,
    name: "Media List",
    component: ListMedia,
  },
  {
    path: "/media-manager/edit/:_id",
    exact: true,
    name: "Add Media",
    component: AddMedia,
  },
  {
    path: "/media-manager/usage",
    exact: true,
    name: "Media Files Usage List",
    component: MediaFilesUsage,
  },
  {
    path: "/allAppointmentDetails",
    exact: true,
    name: "All Appointment Details",
    component: Download,
  },
  {
    path: "/screening-tests",
    exact: true,
    name: "Screening Tests Responses",
    component: ScreeningTestsResponses,
  },
  {
    path: "/screening-tests/report/:_id",
    exact: true,
    name: "Screening Test Report",
    component: ScreeningTestReport,
  },
  {
    path: "/calendar-view",
    exact: true,
    name: "Calendar View",
    component: CalendarView,
  },
  {
    path: "/zoom-report",
    exact: true,
    name: "Zoom Report",
    component: ZoomReport,
  },
];

export default operationRoutes;
