import _ from "lodash";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import useQuery from "src/customHooks/useQuery";
import qs from "query-string";
import SimpleButton from "../buttons/simpleButton";
import Select from "../formFields/select";

const PaginationNew = (props) => {
  const {
    page,
    setPage,
    limit,
    totalPages,
    onClickPrev = () => {},
    onClickNext = () => {},
  } = props;
  // const totalPages = Math.ceil(data?.length / limit);
  const options = Array(totalPages)
    .fill("")
    .map((item, index) => {
      return { key: `${index + 1}`, value: index + 1 };
    });

  return (
    <div className="d-flex justify-content-between">
      <div>
        <strong>Current Page:</strong> {page}
        &nbsp; &nbsp; &nbsp;
        <strong>Total Pages:</strong> {totalPages}
      </div>
      <div className="d-flex">
        <SimpleButton
          title="Previous"
          className="btn-info"
          type="button"
          style={{ height: "37px", marginBottom: 2, marginLeft: 10 }}
          disabled={page === 1}
          onClick={() => {
            setPage(Number(page) - 1);
          }}
        />
        <Select
          options={options}
          onChange={(e) => {
            setPage(e.target.value);
          }}
          value={page}
          noTitle
          selectClassname="pagination-select"
        />
        <SimpleButton
          title="Next"
          className="btn-info"
          type="button"
          style={{ height: "37px", marginBottom: 2 }}
          onClick={() => {
            setPage(Number(page) + 1);
          }}
          disabled={page >= totalPages}
        />
      </div>
    </div>
  );
};

//  ({
//   gotoPage,
//   previousPage,
//   nextPage,
//   canPreviousPage,
//   canNextPage,
//   pageIndex,
//   pageSize,
//   setPageSize,
//   pageCount,
//   pageOptions,
//   setPage,
//   page

// }) => {
//   const history = useHistory();
//   const query = useQuery();
//   const location = useLocation();
//   const queryParams = qs.parse(location.search);

//   useEffect(() => {
//     if (pageIndex + 1 > pageOptions?.length) {
//       gotoPage(0);
//     }
//   }, []);
//   return (
//     <div
//       className="pagination flex-row justify-content-between align-items-center d-flex"
//       style={{ display: "flex", justifyContent: "space-between" }}
//     >
//       <nav aria-label="Page navigation example">
//         <ul class="pagination justify-content-end">
//           <li
//             className={`page-item ${!canPreviousPage ? "disabled" : ""}`}
//             onClick={() => {
//               if (canPreviousPage) {
//                 setPage(page - 1)
//                 const newQueries = {
//                   ...queryParams,
//                   page: parseInt(query?.get("page")) - 1,
//                 };

//                 history.replace({ search: qs.stringify(newQueries) });
//               }
//             }}
//             disabled={!canPreviousPage}
//           >
//             <a class="page-link" href="javascript:void(0)" tabindex="-1">
//               Previous
//             </a>
//           </li>

//           {_.times(pageOptions.length, (index) => {
//             return (
//               <li
//                 className={`page-item ${
//                   pageIndex === index ? "disabled pagination-item" : ""
//                 }`}
//                 onClick={() => {
//                   const newQueries = {
//                     ...queryParams,
//                     page: index + 1,
//                   };

//                   history.replace({ search: qs.stringify(newQueries) });
//                 }}
//               >
//                 <a
//                   class={`page-link ${
//                     pageIndex === index ? "pagination-item" : ""
//                   }`}
//                   href="javascript:void(0)"
//                 >
//                   {index + 1}
//                 </a>
//               </li>
//             );
//           })}

//           <li
//             className={`page-item ${!canNextPage ? "disabled" : ""}`}
//             onClick={() => {
//               if (canNextPage) {
//                 setPage(page + 1)
//                 const newQueries = {
//                   ...queryParams,
//                   page: query?.get("page")
//                     ? parseInt(query?.get("page")) + 1
//                     : 2,
//                 };

//                 history.replace({ search: qs.stringify(newQueries) });
//               }
//             }}
//           >
//             <a class="page-link" href="javascript:void(0)">
//               Next
//             </a>
//           </li>
//         </ul>
//       </nav>

//       {/* <select
//         value={pageSize}
//         onChange={(e) => {
//           setPageSize(Number(e.target.value));
//         }}
//         class="form-select"
//         aria-label="Default select example"
//       >
//         {[5, 10, 20, 30, 40, 50].map((pageSize) => (
//           <option key={pageSize} value={pageSize}>
//             Show {pageSize}
//           </option>
//         ))}
//       </select> */}
//     </div>
//   );
// };

export default PaginationNew;
