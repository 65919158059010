import React from "react";
const ClientDashboard = React.lazy(() =>
  import("../pages/clientSide/ClientDashboard")
);
const ClientProfile = React.lazy(() => import("../pages/clientSide/Profile"));
const HelpCentre = React.lazy(() => import("../pages/clientSide/HelpCentre"));
const PlanDashboard = React.lazy(() =>
  import("../pages/clientSide/PlanDashboard")
);
const AllAppointment = React.lazy(() =>
  import("../pages/clientSide/ClientDashboard/AllAppointment")
);
const Subscribe = React.lazy(() => import("../pages/clientSide/Subscribe"));
const Schedule = React.lazy(() => import("../pages/clientSide/Schedule"));
const Documents = React.lazy(() => import("../pages/clientSide/Documents"));
// const Onboarding = React.lazy(() =>
//   import("../pages/clientSide/Onboarding/OnBoradringDetails")
// );

const clientRoutes = [
  { path: "/client/dashboard", name: "Login Page", component: ClientDashboard },
  { path: "/client/profile", name: "Profile Page", component: ClientProfile },
  { path: "/client/helpcentre", name: "Help Centre", component: HelpCentre },
  { path: "/client/plan", name: "Plan Page", component: PlanDashboard },
  {
    path: "/client/subscribe",
    name: "Subscribe Page",
    component: Subscribe,
  },
  {
    path: "/client/allAppointment",
    name: "Appointment Page",
    component: AllAppointment,
  },
  {
    path: "/client/schedule",
    name: "Schedule Page",
    component: Schedule,
  },
  {
    path: "/client/documents",
    name: "Documents Page",
    component: Documents,
  },
  // {
  //   path: "/client/onboarding",
  //   name: "Onboarding Page",
  //   component: Onboarding,
  // },
];

export default clientRoutes;
