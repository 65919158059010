const timezonesArray = {
  "America/Adak": "(GMT-10:00) America/Adak (Hawaii-Aleutian Standard Time)",
  "America/Atka": "(GMT-10:00) America/Atka (Hawaii-Aleutian Standard Time)",
  "America/Anchorage": "(GMT-9:00) America/Anchorage (Alaska Standard Time)",
  "America/Juneau": "(GMT-9:00) America/Juneau (Alaska Standard Time)",
  "America/Nome": "(GMT-9:00) America/Nome (Alaska Standard Time)",
  "America/Yakutat": "(GMT-9:00) America/Yakutat (Alaska Standard Time)",
  "America/Dawson": "(GMT-8:00) America/Dawson (Pacific Standard Time)",
  "America/Ensenada": "(GMT-8:00) America/Ensenada (Pacific Standard Time)",
  "America/Los_Angeles":
    "(GMT-8:00) America/Los_Angeles (Pacific Standard Time)",
  "America/Tijuana": "(GMT-8:00) America/Tijuana (Pacific Standard Time)",
  "America/Vancouver": "(GMT-8:00) America/Vancouver (Pacific Standard Time)",
  "America/Whitehorse": "(GMT-8:00) America/Whitehorse (Pacific Standard Time)",
  "Canada/Pacific": "(GMT-8:00) Canada/Pacific (Pacific Standard Time)",
  "Canada/Yukon": "(GMT-8:00) Canada/Yukon (Pacific Standard Time)",
  "Mexico/BajaNorte": "(GMT-8:00) Mexico/BajaNorte (Pacific Standard Time)",
  "America/Boise": "(GMT-7:00) America/Boise (Mountain Standard Time)",
  "America/Cambridge_Bay":
    "(GMT-7:00) America/Cambridge_Bay (Mountain Standard Time)",
  "America/Chihuahua": "(GMT-7:00) America/Chihuahua (Mountain Standard Time)",
  "America/Dawson_Creek":
    "(GMT-7:00) America/Dawson_Creek (Mountain Standard Time)",
  "America/Denver": "(GMT-7:00) America/Denver (Mountain Standard Time)",
  "America/Edmonton": "(GMT-7:00) America/Edmonton (Mountain Standard Time)",
  "America/Hermosillo":
    "(GMT-7:00) America/Hermosillo (Mountain Standard Time)",
  "America/Inuvik": "(GMT-7:00) America/Inuvik (Mountain Standard Time)",
  "America/Mazatlan": "(GMT-7:00) America/Mazatlan (Mountain Standard Time)",
  "America/Phoenix": "(GMT-7:00) America/Phoenix (Mountain Standard Time)",
  "America/Shiprock": "(GMT-7:00) America/Shiprock (Mountain Standard Time)",
  "America/Yellowknife":
    "(GMT-7:00) America/Yellowknife (Mountain Standard Time)",
  "Canada/Mountain": "(GMT-7:00) Canada/Mountain (Mountain Standard Time)",
  "Mexico/BajaSur": "(GMT-7:00) Mexico/BajaSur (Mountain Standard Time)",
  "America/Belize": "(GMT-6:00) America/Belize (Central Standard Time)",
  "America/Cancun": "(GMT-6:00) America/Cancun (Central Standard Time)",
  "America/Chicago": "(GMT-6:00) America/Chicago (Central Standard Time)",
  "America/Costa_Rica": "(GMT-6:00) America/Costa_Rica (Central Standard Time)",
  "America/El_Salvador":
    "(GMT-6:00) America/El_Salvador (Central Standard Time)",
  "America/Guatemala": "(GMT-6:00) America/Guatemala (Central Standard Time)",
  "America/Knox_IN": "(GMT-6:00) America/Knox_IN (Central Standard Time)",
  "America/Managua": "(GMT-6:00) America/Managua (Central Standard Time)",
  "America/Menominee": "(GMT-6:00) America/Menominee (Central Standard Time)",
  "America/Merida": "(GMT-6:00) America/Merida (Central Standard Time)",
  "America/Mexico_City":
    "(GMT-6:00) America/Mexico_City (Central Standard Time)",
  "America/Monterrey": "(GMT-6:00) America/Monterrey (Central Standard Time)",
  "America/Rainy_River":
    "(GMT-6:00) America/Rainy_River (Central Standard Time)",
  "America/Rankin_Inlet":
    "(GMT-6:00) America/Rankin_Inlet (Central Standard Time)",
  "America/Regina": "(GMT-6:00) America/Regina (Central Standard Time)",
  "America/Swift_Current":
    "(GMT-6:00) America/Swift_Current (Central Standard Time)",
  "America/Tegucigalpa":
    "(GMT-6:00) America/Tegucigalpa (Central Standard Time)",
  "America/Winnipeg": "(GMT-6:00) America/Winnipeg (Central Standard Time)",
  "Canada/Central": "(GMT-6:00) Canada/Central (Central Standard Time)",
  "Canada/East-Saskatchewan":
    "(GMT-6:00) Canada/East-Saskatchewan (Central Standard Time)",
  "Canada/Saskatchewan":
    "(GMT-6:00) Canada/Saskatchewan (Central Standard Time)",
  "Chile/EasterIsland": "(GMT-6:00) Chile/EasterIsland (Easter Is. Time)",
  "Mexico/General": "(GMT-6:00) Mexico/General (Central Standard Time)",
  "America/Atikokan": "(GMT-5:00) America/Atikokan (Eastern Standard Time)",
  "America/Bogota": "(GMT-5:00) America/Bogota (Colombia Time)",
  "America/Cayman": "(GMT-5:00) America/Cayman (Eastern Standard Time)",
  "America/Coral_Harbour":
    "(GMT-5:00) America/Coral_Harbour (Eastern Standard Time)",
  "America/Detroit": "(GMT-5:00) America/Detroit (Eastern Standard Time)",
  "America/Fort_Wayne": "(GMT-5:00) America/Fort_Wayne (Eastern Standard Time)",
  "America/Grand_Turk": "(GMT-5:00) America/Grand_Turk (Eastern Standard Time)",
  "America/Guayaquil": "(GMT-5:00) America/Guayaquil (Ecuador Time)",
  "America/Havana": "(GMT-5:00) America/Havana (Cuba Standard Time)",
  "America/Indianapolis":
    "(GMT-5:00) America/Indianapolis (Eastern Standard Time)",
  "America/Iqaluit": "(GMT-5:00) America/Iqaluit (Eastern Standard Time)",
  "America/Jamaica": "(GMT-5:00) America/Jamaica (Eastern Standard Time)",
  "America/Lima": "(GMT-5:00) America/Lima (Peru Time)",
  "America/Louisville": "(GMT-5:00) America/Louisville (Eastern Standard Time)",
  "America/Montreal": "(GMT-5:00) America/Montreal (Eastern Standard Time)",
  "America/Nassau": "(GMT-5:00) America/Nassau (Eastern Standard Time)",
  "America/New_York": "(GMT-5:00) America/New_York (Eastern Standard Time)",
  "America/Nipigon": "(GMT-5:00) America/Nipigon (Eastern Standard Time)",
  "America/Panama": "(GMT-5:00) America/Panama (Eastern Standard Time)",
  "America/Pangnirtung":
    "(GMT-5:00) America/Pangnirtung (Eastern Standard Time)",
  "America/Port-au-Prince":
    "(GMT-5:00) America/Port-au-Prince (Eastern Standard Time)",
  "America/Resolute": "(GMT-5:00) America/Resolute (Eastern Standard Time)",
  "America/Thunder_Bay":
    "(GMT-5:00) America/Thunder_Bay (Eastern Standard Time)",
  "America/Toronto": "(GMT-5:00) America/Toronto (Eastern Standard Time)",
  "Canada/Eastern": "(GMT-5:00) Canada/Eastern (Eastern Standard Time)",
  "America/Caracas": "(GMT-4:-30) America/Caracas (Venezuela Time)",
  "America/Anguilla": "(GMT-4:00) America/Anguilla (Atlantic Standard Time)",
  "America/Antigua": "(GMT-4:00) America/Antigua (Atlantic Standard Time)",
  "America/Aruba": "(GMT-4:00) America/Aruba (Atlantic Standard Time)",
  "America/Asuncion": "(GMT-4:00) America/Asuncion (Paraguay Time)",
  "America/Barbados": "(GMT-4:00) America/Barbados (Atlantic Standard Time)",
  "America/Blanc-Sablon":
    "(GMT-4:00) America/Blanc-Sablon (Atlantic Standard Time)",
  "America/Boa_Vista": "(GMT-4:00) America/Boa_Vista (Amazon Time)",
  "America/Campo_Grande": "(GMT-4:00) America/Campo_Grande (Amazon Time)",
  "America/Cuiaba": "(GMT-4:00) America/Cuiaba (Amazon Time)",
  "America/Curacao": "(GMT-4:00) America/Curacao (Atlantic Standard Time)",
  "America/Dominica": "(GMT-4:00) America/Dominica (Atlantic Standard Time)",
  "America/Eirunepe": "(GMT-4:00) America/Eirunepe (Amazon Time)",
  "America/Glace_Bay": "(GMT-4:00) America/Glace_Bay (Atlantic Standard Time)",
  "America/Goose_Bay": "(GMT-4:00) America/Goose_Bay (Atlantic Standard Time)",
  "America/Grenada": "(GMT-4:00) America/Grenada (Atlantic Standard Time)",
  "America/Guadeloupe":
    "(GMT-4:00) America/Guadeloupe (Atlantic Standard Time)",
  "America/Guyana": "(GMT-4:00) America/Guyana (Guyana Time)",
  "America/Halifax": "(GMT-4:00) America/Halifax (Atlantic Standard Time)",
  "America/La_Paz": "(GMT-4:00) America/La_Paz (Bolivia Time)",
  "America/Manaus": "(GMT-4:00) America/Manaus (Amazon Time)",
  "America/Marigot": "(GMT-4:00) America/Marigot (Atlantic Standard Time)",
  "America/Martinique":
    "(GMT-4:00) America/Martinique (Atlantic Standard Time)",
  "America/Moncton": "(GMT-4:00) America/Moncton (Atlantic Standard Time)",
  "America/Montserrat":
    "(GMT-4:00) America/Montserrat (Atlantic Standard Time)",
  "America/Port_of_Spain":
    "(GMT-4:00) America/Port_of_Spain (Atlantic Standard Time)",
  "America/Porto_Acre": "(GMT-4:00) America/Porto_Acre (Amazon Time)",
  "America/Porto_Velho": "(GMT-4:00) America/Porto_Velho (Amazon Time)",
  "America/Puerto_Rico":
    "(GMT-4:00) America/Puerto_Rico (Atlantic Standard Time)",
  "America/Rio_Branco": "(GMT-4:00) America/Rio_Branco (Amazon Time)",
  "America/Santiago": "(GMT-4:00) America/Santiago (Chile Time)",
  "America/Santo_Domingo":
    "(GMT-4:00) America/Santo_Domingo (Atlantic Standard Time)",
  "America/St_Barthelemy":
    "(GMT-4:00) America/St_Barthelemy (Atlantic Standard Time)",
  "America/St_Kitts": "(GMT-4:00) America/St_Kitts (Atlantic Standard Time)",
  "America/St_Lucia": "(GMT-4:00) America/St_Lucia (Atlantic Standard Time)",
  "America/St_Thomas": "(GMT-4:00) America/St_Thomas (Atlantic Standard Time)",
  "America/St_Vincent":
    "(GMT-4:00) America/St_Vincent (Atlantic Standard Time)",
  "America/Thule": "(GMT-4:00) America/Thule (Atlantic Standard Time)",
  "America/Tortola": "(GMT-4:00) America/Tortola (Atlantic Standard Time)",
  "America/Virgin": "(GMT-4:00) America/Virgin (Atlantic Standard Time)",
  "Antarctica/Palmer": "(GMT-4:00) Antarctica/Palmer (Chile Time)",
  "Atlantic/Bermuda": "(GMT-4:00) Atlantic/Bermuda (Atlantic Standard Time)",
  "Atlantic/Stanley": "(GMT-4:00) Atlantic/Stanley (Falkland Is. Time)",
  "Brazil/Acre": "(GMT-4:00) Brazil/Acre (Amazon Time)",
  "Brazil/West": "(GMT-4:00) Brazil/West (Amazon Time)",
  "Canada/Atlantic": "(GMT-4:00) Canada/Atlantic (Atlantic Standard Time)",
  "Chile/Continental": "(GMT-4:00) Chile/Continental (Chile Time)",
  "America/St_Johns":
    "(GMT-3:-30) America/St_Johns (Newfoundland Standard Time)",
  "Canada/Newfoundland":
    "(GMT-3:-30) Canada/Newfoundland (Newfoundland Standard Time)",
  "America/Araguaina": "(GMT-3:00) America/Araguaina (Brasilia Time)",
  "America/Bahia": "(GMT-3:00) America/Bahia (Brasilia Time)",
  "America/Belem": "(GMT-3:00) America/Belem (Brasilia Time)",
  "America/Buenos_Aires": "(GMT-3:00) America/Buenos_Aires (Argentine Time)",
  "America/Catamarca": "(GMT-3:00) America/Catamarca (Argentine Time)",
  "America/Cayenne": "(GMT-3:00) America/Cayenne (French Guiana Time)",
  "America/Cordoba": "(GMT-3:00) America/Cordoba (Argentine Time)",
  "America/Fortaleza": "(GMT-3:00) America/Fortaleza (Brasilia Time)",
  "America/Godthab": "(GMT-3:00) America/Godthab (Western Greenland Time)",
  "America/Jujuy": "(GMT-3:00) America/Jujuy (Argentine Time)",
  "America/Maceio": "(GMT-3:00) America/Maceio (Brasilia Time)",
  "America/Mendoza": "(GMT-3:00) America/Mendoza (Argentine Time)",
  "America/Miquelon":
    "(GMT-3:00) America/Miquelon (Pierre & Miquelon Standard Time)",
  "America/Montevideo": "(GMT-3:00) America/Montevideo (Uruguay Time)",
  "America/Paramaribo": "(GMT-3:00) America/Paramaribo (Suriname Time)",
  "America/Recife": "(GMT-3:00) America/Recife (Brasilia Time)",
  "America/Rosario": "(GMT-3:00) America/Rosario (Argentine Time)",
  "America/Santarem": "(GMT-3:00) America/Santarem (Brasilia Time)",
  "America/Sao_Paulo": "(GMT-3:00) America/Sao_Paulo (Brasilia Time)",
  "Antarctica/Rothera": "(GMT-3:00) Antarctica/Rothera (Rothera Time)",
  "Brazil/East": "(GMT-3:00) Brazil/East (Brasilia Time)",
  "America/Noronha": "(GMT-2:00) America/Noronha (Fernando de Noronha Time)",
  "Atlantic/South_Georgia":
    "(GMT-2:00) Atlantic/South_Georgia (South Georgia Standard Time)",
  "Brazil/DeNoronha": "(GMT-2:00) Brazil/DeNoronha (Fernando de Noronha Time)",
  "America/Scoresbysund":
    "(GMT-1:00) America/Scoresbysund (Eastern Greenland Time)",
  "Atlantic/Azores": "(GMT-1:00) Atlantic/Azores (Azores Time)",
  "Atlantic/Cape_Verde": "(GMT-1:00) Atlantic/Cape_Verde (Cape Verde Time)",
  "Africa/Abidjan": "(GMT+0:00) Africa/Abidjan (Greenwich Mean Time)",
  "Africa/Accra": "(GMT+0:00) Africa/Accra (Ghana Mean Time)",
  "Africa/Bamako": "(GMT+0:00) Africa/Bamako (Greenwich Mean Time)",
  "Africa/Banjul": "(GMT+0:00) Africa/Banjul (Greenwich Mean Time)",
  "Africa/Bissau": "(GMT+0:00) Africa/Bissau (Greenwich Mean Time)",
  "Africa/Casablanca": "(GMT+0:00) Africa/Casablanca (Western European Time)",
  "Africa/Conakry": "(GMT+0:00) Africa/Conakry (Greenwich Mean Time)",
  "Africa/Dakar": "(GMT+0:00) Africa/Dakar (Greenwich Mean Time)",
  "Africa/El_Aaiun": "(GMT+0:00) Africa/El_Aaiun (Western European Time)",
  "Africa/Freetown": "(GMT+0:00) Africa/Freetown (Greenwich Mean Time)",
  "Africa/Lome": "(GMT+0:00) Africa/Lome (Greenwich Mean Time)",
  "Africa/Monrovia": "(GMT+0:00) Africa/Monrovia (Greenwich Mean Time)",
  "Africa/Nouakchott": "(GMT+0:00) Africa/Nouakchott (Greenwich Mean Time)",
  "Africa/Ouagadougou": "(GMT+0:00) Africa/Ouagadougou (Greenwich Mean Time)",
  "Africa/Sao_Tome": "(GMT+0:00) Africa/Sao_Tome (Greenwich Mean Time)",
  "Africa/Timbuktu": "(GMT+0:00) Africa/Timbuktu (Greenwich Mean Time)",
  "America/Danmarkshavn":
    "(GMT+0:00) America/Danmarkshavn (Greenwich Mean Time)",
  "Atlantic/Canary": "(GMT+0:00) Atlantic/Canary (Western European Time)",
  "Atlantic/Faeroe": "(GMT+0:00) Atlantic/Faeroe (Western European Time)",
  "Atlantic/Faroe": "(GMT+0:00) Atlantic/Faroe (Western European Time)",
  "Atlantic/Madeira": "(GMT+0:00) Atlantic/Madeira (Western European Time)",
  "Atlantic/Reykjavik": "(GMT+0:00) Atlantic/Reykjavik (Greenwich Mean Time)",
  "Atlantic/St_Helena": "(GMT+0:00) Atlantic/St_Helena (Greenwich Mean Time)",
  "Europe/Belfast": "(GMT+0:00) Europe/Belfast (Greenwich Mean Time)",
  "Europe/Dublin": "(GMT+0:00) Europe/Dublin (Greenwich Mean Time)",
  "Europe/Guernsey": "(GMT+0:00) Europe/Guernsey (Greenwich Mean Time)",
  "Europe/Isle_of_Man": "(GMT+0:00) Europe/Isle_of_Man (Greenwich Mean Time)",
  "Europe/Jersey": "(GMT+0:00) Europe/Jersey (Greenwich Mean Time)",
  "Europe/Lisbon": "(GMT+0:00) Europe/Lisbon (Western European Time)",
  "Europe/London": "(GMT+0:00) Europe/London (Greenwich Mean Time)",
  "Africa/Algiers": "(GMT+1:00) Africa/Algiers (Central European Time)",
  "Africa/Bangui": "(GMT+1:00) Africa/Bangui (Western African Time)",
  "Africa/Brazzaville": "(GMT+1:00) Africa/Brazzaville (Western African Time)",
  "Africa/Ceuta": "(GMT+1:00) Africa/Ceuta (Central European Time)",
  "Africa/Douala": "(GMT+1:00) Africa/Douala (Western African Time)",
  "Africa/Kinshasa": "(GMT+1:00) Africa/Kinshasa (Western African Time)",
  "Africa/Lagos": "(GMT+1:00) Africa/Lagos (Western African Time)",
  "Africa/Libreville": "(GMT+1:00) Africa/Libreville (Western African Time)",
  "Africa/Luanda": "(GMT+1:00) Africa/Luanda (Western African Time)",
  "Africa/Malabo": "(GMT+1:00) Africa/Malabo (Western African Time)",
  "Africa/Ndjamena": "(GMT+1:00) Africa/Ndjamena (Western African Time)",
  "Africa/Niamey": "(GMT+1:00) Africa/Niamey (Western African Time)",
  "Africa/Porto-Novo": "(GMT+1:00) Africa/Porto-Novo (Western African Time)",
  "Africa/Tunis": "(GMT+1:00) Africa/Tunis (Central European Time)",
  "Africa/Windhoek": "(GMT+1:00) Africa/Windhoek (Western African Time)",
  "Arctic/Longyearbyen":
    "(GMT+1:00) Arctic/Longyearbyen (Central European Time)",
  "Atlantic/Jan_Mayen": "(GMT+1:00) Atlantic/Jan_Mayen (Central European Time)",
  "Europe/Amsterdam": "(GMT+1:00) Europe/Amsterdam (Central European Time)",
  "Europe/Andorra": "(GMT+1:00) Europe/Andorra (Central European Time)",
  "Europe/Belgrade": "(GMT+1:00) Europe/Belgrade (Central European Time)",
  "Europe/Berlin": "(GMT+1:00) Europe/Berlin (Central European Time)",
  "Europe/Bratislava": "(GMT+1:00) Europe/Bratislava (Central European Time)",
  "Europe/Brussels": "(GMT+1:00) Europe/Brussels (Central European Time)",
  "Europe/Budapest": "(GMT+1:00) Europe/Budapest (Central European Time)",
  "Europe/Copenhagen": "(GMT+1:00) Europe/Copenhagen (Central European Time)",
  "Europe/Gibraltar": "(GMT+1:00) Europe/Gibraltar (Central European Time)",
  "Europe/Ljubljana": "(GMT+1:00) Europe/Ljubljana (Central European Time)",
  "Europe/Luxembourg": "(GMT+1:00) Europe/Luxembourg (Central European Time)",
  "Europe/Madrid": "(GMT+1:00) Europe/Madrid (Central European Time)",
  "Europe/Malta": "(GMT+1:00) Europe/Malta (Central European Time)",
  "Europe/Monaco": "(GMT+1:00) Europe/Monaco (Central European Time)",
  "Europe/Oslo": "(GMT+1:00) Europe/Oslo (Central European Time)",
  "Europe/Paris": "(GMT+1:00) Europe/Paris (Central European Time)",
  "Europe/Podgorica": "(GMT+1:00) Europe/Podgorica (Central European Time)",
  "Europe/Prague": "(GMT+1:00) Europe/Prague (Central European Time)",
  "Europe/Rome": "(GMT+1:00) Europe/Rome (Central European Time)",
  "Europe/San_Marino": "(GMT+1:00) Europe/San_Marino (Central European Time)",
  "Europe/Sarajevo": "(GMT+1:00) Europe/Sarajevo (Central European Time)",
  "Europe/Skopje": "(GMT+1:00) Europe/Skopje (Central European Time)",
  "Europe/Stockholm": "(GMT+1:00) Europe/Stockholm (Central European Time)",
  "Europe/Tirane": "(GMT+1:00) Europe/Tirane (Central European Time)",
  "Europe/Vaduz": "(GMT+1:00) Europe/Vaduz (Central European Time)",
  "Europe/Vatican": "(GMT+1:00) Europe/Vatican (Central European Time)",
  "Europe/Vienna": "(GMT+1:00) Europe/Vienna (Central European Time)",
  "Europe/Warsaw": "(GMT+1:00) Europe/Warsaw (Central European Time)",
  "Europe/Zagreb": "(GMT+1:00) Europe/Zagreb (Central European Time)",
  "Europe/Zurich": "(GMT+1:00) Europe/Zurich (Central European Time)",
  "Africa/Blantyre": "(GMT+2:00) Africa/Blantyre (Central African Time)",
  "Africa/Bujumbura": "(GMT+2:00) Africa/Bujumbura (Central African Time)",
  "Africa/Cairo": "(GMT+2:00) Africa/Cairo (Eastern European Time)",
  "Africa/Gaborone": "(GMT+2:00) Africa/Gaborone (Central African Time)",
  "Africa/Harare": "(GMT+2:00) Africa/Harare (Central African Time)",
  "Africa/Johannesburg":
    "(GMT+2:00) Africa/Johannesburg (South Africa Standard Time)",
  "Africa/Kigali": "(GMT+2:00) Africa/Kigali (Central African Time)",
  "Africa/Lubumbashi": "(GMT+2:00) Africa/Lubumbashi (Central African Time)",
  "Africa/Lusaka": "(GMT+2:00) Africa/Lusaka (Central African Time)",
  "Africa/Maputo": "(GMT+2:00) Africa/Maputo (Central African Time)",
  "Africa/Maseru": "(GMT+2:00) Africa/Maseru (South Africa Standard Time)",
  "Africa/Mbabane": "(GMT+2:00) Africa/Mbabane (South Africa Standard Time)",
  "Africa/Tripoli": "(GMT+2:00) Africa/Tripoli (Eastern European Time)",
  "Asia/Amman": "(GMT+2:00) Asia/Amman (Eastern European Time)",
  "Asia/Beirut": "(GMT+2:00) Asia/Beirut (Eastern European Time)",
  "Asia/Damascus": "(GMT+2:00) Asia/Damascus (Eastern European Time)",
  "Asia/Gaza": "(GMT+2:00) Asia/Gaza (Eastern European Time)",
  "Asia/Istanbul": "(GMT+2:00) Asia/Istanbul (Eastern European Time)",
  "Asia/Jerusalem": "(GMT+2:00) Asia/Jerusalem (Israel Standard Time)",
  "Asia/Nicosia": "(GMT+2:00) Asia/Nicosia (Eastern European Time)",
  "Asia/Tel_Aviv": "(GMT+2:00) Asia/Tel_Aviv (Israel Standard Time)",
  "Europe/Athens": "(GMT+2:00) Europe/Athens (Eastern European Time)",
  "Europe/Bucharest": "(GMT+2:00) Europe/Bucharest (Eastern European Time)",
  "Europe/Chisinau": "(GMT+2:00) Europe/Chisinau (Eastern European Time)",
  "Europe/Helsinki": "(GMT+2:00) Europe/Helsinki (Eastern European Time)",
  "Europe/Istanbul": "(GMT+2:00) Europe/Istanbul (Eastern European Time)",
  "Europe/Kaliningrad": "(GMT+2:00) Europe/Kaliningrad (Eastern European Time)",
  "Europe/Kiev": "(GMT+2:00) Europe/Kiev (Eastern European Time)",
  "Europe/Mariehamn": "(GMT+2:00) Europe/Mariehamn (Eastern European Time)",
  "Europe/Minsk": "(GMT+2:00) Europe/Minsk (Eastern European Time)",
  "Europe/Nicosia": "(GMT+2:00) Europe/Nicosia (Eastern European Time)",
  "Europe/Riga": "(GMT+2:00) Europe/Riga (Eastern European Time)",
  "Europe/Simferopol": "(GMT+2:00) Europe/Simferopol (Eastern European Time)",
  "Europe/Sofia": "(GMT+2:00) Europe/Sofia (Eastern European Time)",
  "Europe/Tallinn": "(GMT+2:00) Europe/Tallinn (Eastern European Time)",
  "Europe/Tiraspol": "(GMT+2:00) Europe/Tiraspol (Eastern European Time)",
  "Europe/Uzhgorod": "(GMT+2:00) Europe/Uzhgorod (Eastern European Time)",
  "Europe/Vilnius": "(GMT+2:00) Europe/Vilnius (Eastern European Time)",
  "Europe/Zaporozhye": "(GMT+2:00) Europe/Zaporozhye (Eastern European Time)",
  "Africa/Addis_Ababa": "(GMT+3:00) Africa/Addis_Ababa (Eastern African Time)",
  "Africa/Asmara": "(GMT+3:00) Africa/Asmara (Eastern African Time)",
  "Africa/Asmera": "(GMT+3:00) Africa/Asmera (Eastern African Time)",
  "Africa/Dar_es_Salaam":
    "(GMT+3:00) Africa/Dar_es_Salaam (Eastern African Time)",
  "Africa/Djibouti": "(GMT+3:00) Africa/Djibouti (Eastern African Time)",
  "Africa/Kampala": "(GMT+3:00) Africa/Kampala (Eastern African Time)",
  "Africa/Khartoum": "(GMT+3:00) Africa/Khartoum (Eastern African Time)",
  "Africa/Mogadishu": "(GMT+3:00) Africa/Mogadishu (Eastern African Time)",
  "Africa/Nairobi": "(GMT+3:00) Africa/Nairobi (Eastern African Time)",
  "Antarctica/Syowa": "(GMT+3:00) Antarctica/Syowa (Syowa Time)",
  "Asia/Aden": "(GMT+3:00) Asia/Aden (Arabia Standard Time)",
  "Asia/Baghdad": "(GMT+3:00) Asia/Baghdad (Arabia Standard Time)",
  "Asia/Bahrain": "(GMT+3:00) Asia/Bahrain (Arabia Standard Time)",
  "Asia/Kuwait": "(GMT+3:00) Asia/Kuwait (Arabia Standard Time)",
  "Asia/Qatar": "(GMT+3:00) Asia/Qatar (Arabia Standard Time)",
  "Europe/Moscow": "(GMT+3:00) Europe/Moscow (Moscow Standard Time)",
  "Europe/Volgograd": "(GMT+3:00) Europe/Volgograd (Volgograd Time)",
  "Indian/Antananarivo":
    "(GMT+3:00) Indian/Antananarivo (Eastern African Time)",
  "Indian/Comoro": "(GMT+3:00) Indian/Comoro (Eastern African Time)",
  "Indian/Mayotte": "(GMT+3:00) Indian/Mayotte (Eastern African Time)",
  "Asia/Tehran": "(GMT+3:30) Asia/Tehran (Iran Standard Time)",
  "Asia/Baku": "(GMT+4:00) Asia/Baku (Azerbaijan Time)",
  "Asia/Dubai": "(GMT+4:00) Asia/Dubai (Gulf Standard Time)",
  "Asia/Muscat": "(GMT+4:00) Asia/Muscat (Gulf Standard Time)",
  "Asia/Tbilisi": "(GMT+4:00) Asia/Tbilisi (Georgia Time)",
  "Asia/Yerevan": "(GMT+4:00) Asia/Yerevan (Armenia Time)",
  "Europe/Samara": "(GMT+4:00) Europe/Samara (Samara Time)",
  "Indian/Mahe": "(GMT+4:00) Indian/Mahe (Seychelles Time)",
  "Indian/Mauritius": "(GMT+4:00) Indian/Mauritius (Mauritius Time)",
  "Indian/Reunion": "(GMT+4:00) Indian/Reunion (Reunion Time)",
  "Asia/Kabul": "(GMT+4:30) Asia/Kabul (Afghanistan Time)",
  "Asia/Aqtau": "(GMT+5:00) Asia/Aqtau (Aqtau Time)",
  "Asia/Aqtobe": "(GMT+5:00) Asia/Aqtobe (Aqtobe Time)",
  "Asia/Ashgabat": "(GMT+5:00) Asia/Ashgabat (Turkmenistan Time)",
  "Asia/Ashkhabad": "(GMT+5:00) Asia/Ashkhabad (Turkmenistan Time)",
  "Asia/Dushanbe": "(GMT+5:00) Asia/Dushanbe (Tajikistan Time)",
  "Asia/Karachi": "(GMT+5:00) Asia/Karachi (Pakistan Time)",
  "Asia/Oral": "(GMT+5:00) Asia/Oral (Oral Time)",
  "Asia/Samarkand": "(GMT+5:00) Asia/Samarkand (Uzbekistan Time)",
  "Asia/Tashkent": "(GMT+5:00) Asia/Tashkent (Uzbekistan Time)",
  "Asia/Yekaterinburg": "(GMT+5:00) Asia/Yekaterinburg (Yekaterinburg Time)",
  "Indian/Kerguelen":
    "(GMT+5:00) Indian/Kerguelen (French Southern & Antarctic Lands Time)",
  "Indian/Maldives": "(GMT+5:00) Indian/Maldives (Maldives Time)",
  "Asia/Calcutta": "(GMT+5:30) Asia/Calcutta (India Standard Time)",
  "Asia/Colombo": "(GMT+5:30) Asia/Colombo (India Standard Time)",
  "Asia/Kolkata": "(GMT+5:30) Asia/Kolkata (India Standard Time)",
  "Asia/Katmandu": "(GMT+5:45) Asia/Katmandu (Nepal Time)",
  "Antarctica/Mawson": "(GMT+6:00) Antarctica/Mawson (Mawson Time)",
  "Antarctica/Vostok": "(GMT+6:00) Antarctica/Vostok (Vostok Time)",
  "Asia/Almaty": "(GMT+6:00) Asia/Almaty (Alma-Ata Time)",
  "Asia/Bishkek": "(GMT+6:00) Asia/Bishkek (Kirgizstan Time)",
  "Asia/Dacca": "(GMT+6:00) Asia/Dacca (Bangladesh Time)",
  "Asia/Dhaka": "(GMT+6:00) Asia/Dhaka (Bangladesh Time)",
  "Asia/Novosibirsk": "(GMT+6:00) Asia/Novosibirsk (Novosibirsk Time)",
  "Asia/Omsk": "(GMT+6:00) Asia/Omsk (Omsk Time)",
  "Asia/Qyzylorda": "(GMT+6:00) Asia/Qyzylorda (Qyzylorda Time)",
  "Asia/Thimbu": "(GMT+6:00) Asia/Thimbu (Bhutan Time)",
  "Asia/Thimphu": "(GMT+6:00) Asia/Thimphu (Bhutan Time)",
  "Indian/Chagos": "(GMT+6:00) Indian/Chagos (Indian Ocean Territory Time)",
  "Asia/Rangoon": "(GMT+6:30) Asia/Rangoon (Myanmar Time)",
  "Indian/Cocos": "(GMT+6:30) Indian/Cocos (Cocos Islands Time)",
  "Antarctica/Davis": "(GMT+7:00) Antarctica/Davis (Davis Time)",
  "Asia/Bangkok": "(GMT+7:00) Asia/Bangkok (Indochina Time)",
  "Asia/Ho_Chi_Minh": "(GMT+7:00) Asia/Ho_Chi_Minh (Indochina Time)",
  "Asia/Hovd": "(GMT+7:00) Asia/Hovd (Hovd Time)",
  "Asia/Jakarta": "(GMT+7:00) Asia/Jakarta (West Indonesia Time)",
  "Asia/Krasnoyarsk": "(GMT+7:00) Asia/Krasnoyarsk (Krasnoyarsk Time)",
  "Asia/Phnom_Penh": "(GMT+7:00) Asia/Phnom_Penh (Indochina Time)",
  "Asia/Pontianak": "(GMT+7:00) Asia/Pontianak (West Indonesia Time)",
  "Asia/Saigon": "(GMT+7:00) Asia/Saigon (Indochina Time)",
  "Asia/Vientiane": "(GMT+7:00) Asia/Vientiane (Indochina Time)",
  "Indian/Christmas": "(GMT+7:00) Indian/Christmas (Christmas Island Time)",
  "Antarctica/Casey":
    "(GMT+8:00) Antarctica/Casey (Western Standard Time (Australia))",
  "Asia/Brunei": "(GMT+8:00) Asia/Brunei (Brunei Time)",
  "Asia/Choibalsan": "(GMT+8:00) Asia/Choibalsan (Choibalsan Time)",
  "Asia/Chongqing": "(GMT+8:00) Asia/Chongqing (China Standard Time)",
  "Asia/Chungking": "(GMT+8:00) Asia/Chungking (China Standard Time)",
  "Asia/Harbin": "(GMT+8:00) Asia/Harbin (China Standard Time)",
  "Asia/Hong_Kong": "(GMT+8:00) Asia/Hong_Kong (Hong Kong Time)",
  "Asia/Irkutsk": "(GMT+8:00) Asia/Irkutsk (Irkutsk Time)",
  "Asia/Kashgar": "(GMT+8:00) Asia/Kashgar (China Standard Time)",
  "Asia/Kuala_Lumpur": "(GMT+8:00) Asia/Kuala_Lumpur (Malaysia Time)",
  "Asia/Kuching": "(GMT+8:00) Asia/Kuching (Malaysia Time)",
  "Asia/Macao": "(GMT+8:00) Asia/Macao (China Standard Time)",
  "Asia/Macau": "(GMT+8:00) Asia/Macau (China Standard Time)",
  "Asia/Makassar": "(GMT+8:00) Asia/Makassar (Central Indonesia Time)",
  "Asia/Manila": "(GMT+8:00) Asia/Manila (Philippines Time)",
  "Asia/Shanghai": "(GMT+8:00) Asia/Shanghai (China Standard Time)",
  "Asia/Singapore": "(GMT+8:00) Asia/Singapore (Singapore Time)",
  "Asia/Taipei": "(GMT+8:00) Asia/Taipei (China Standard Time)",
  "Asia/Ujung_Pandang":
    "(GMT+8:00) Asia/Ujung_Pandang (Central Indonesia Time)",
  "Asia/Ulaanbaatar": "(GMT+8:00) Asia/Ulaanbaatar (Ulaanbaatar Time)",
  "Asia/Ulan_Bator": "(GMT+8:00) Asia/Ulan_Bator (Ulaanbaatar Time)",
  "Asia/Urumqi": "(GMT+8:00) Asia/Urumqi (China Standard Time)",
  "Australia/Perth":
    "(GMT+8:00) Australia/Perth (Western Standard Time (Australia))",
  "Australia/West":
    "(GMT+8:00) Australia/West (Western Standard Time (Australia))",
  "Australia/Eucla":
    "(GMT+8:45) Australia/Eucla (Central Western Standard Time (Australia))",
  "Asia/Dili": "(GMT+9:00) Asia/Dili (Timor-Leste Time)",
  "Asia/Jayapura": "(GMT+9:00) Asia/Jayapura (East Indonesia Time)",
  "Asia/Pyongyang": "(GMT+9:00) Asia/Pyongyang (Korea Standard Time)",
  "Asia/Seoul": "(GMT+9:00) Asia/Seoul (Korea Standard Time)",
  "Asia/Tokyo": "(GMT+9:00) Asia/Tokyo (Japan Standard Time)",
  "Asia/Yakutsk": "(GMT+9:00) Asia/Yakutsk (Yakutsk Time)",
  "Australia/Adelaide":
    "(GMT+9:30) Australia/Adelaide (Central Standard Time (South Australia))",
  "Australia/Broken_Hill":
    "(GMT+9:30) Australia/Broken_Hill (Central Standard Time (South Australia/New South Wales))",
  "Australia/Darwin":
    "(GMT+9:30) Australia/Darwin (Central Standard Time (Northern Territory))",
  "Australia/North":
    "(GMT+9:30) Australia/North (Central Standard Time (Northern Territory))",
  "Australia/South":
    "(GMT+9:30) Australia/South (Central Standard Time (South Australia))",
  "Australia/Yancowinna":
    "(GMT+9:30) Australia/Yancowinna (Central Standard Time (South Australia/New South Wales))",
  "Antarctica/DumontDUrville":
    "(GMT+10:00) Antarctica/DumontDUrville (Dumont-d'Urville Time)",
  "Asia/Sakhalin": "(GMT+10:00) Asia/Sakhalin (Sakhalin Time)",
  "Asia/Vladivostok": "(GMT+10:00) Asia/Vladivostok (Vladivostok Time)",
  "Australia/ACT":
    "(GMT+10:00) Australia/ACT (Eastern Standard Time (New South Wales))",
  "Australia/Brisbane":
    "(GMT+10:00) Australia/Brisbane (Eastern Standard Time (Queensland))",
  "Australia/Canberra":
    "(GMT+10:00) Australia/Canberra (Eastern Standard Time (New South Wales))",
  "Australia/Currie":
    "(GMT+10:00) Australia/Currie (Eastern Standard Time (New South Wales))",
  "Australia/Hobart":
    "(GMT+10:00) Australia/Hobart (Eastern Standard Time (Tasmania))",
  "Australia/Lindeman":
    "(GMT+10:00) Australia/Lindeman (Eastern Standard Time (Queensland))",
  "Australia/Melbourne":
    "(GMT+10:00) Australia/Melbourne (Eastern Standard Time (Victoria))",
  "Australia/NSW":
    "(GMT+10:00) Australia/NSW (Eastern Standard Time (New South Wales))",
  "Australia/Queensland":
    "(GMT+10:00) Australia/Queensland (Eastern Standard Time (Queensland))",
  "Australia/Sydney":
    "(GMT+10:00) Australia/Sydney (Eastern Standard Time (New South Wales))",
  "Australia/Tasmania":
    "(GMT+10:00) Australia/Tasmania (Eastern Standard Time (Tasmania))",
  "Australia/Victoria":
    "(GMT+10:00) Australia/Victoria (Eastern Standard Time (Victoria))",
  "Australia/LHI": "(GMT+10:30) Australia/LHI (Lord Howe Standard Time)",
  "Australia/Lord_Howe":
    "(GMT+10:30) Australia/Lord_Howe (Lord Howe Standard Time)",
  "Asia/Magadan": "(GMT+11:00) Asia/Magadan (Magadan Time)",
  "Antarctica/McMurdo":
    "(GMT+12:00) Antarctica/McMurdo (New Zealand Standard Time)",
  "Antarctica/South_Pole":
    "(GMT+12:00) Antarctica/South_Pole (New Zealand Standard Time)",
  "Asia/Anadyr": "(GMT+12:00) Asia/Anadyr (Anadyr Time)",
  "Asia/Kamchatka":
    "(GMT+12:00) Asia/Kamchatka (Petropavlovsk-Kamchatski Time)",
};

export const timezones = Object.keys(timezonesArray).map((item) => ({
  label: item,
  value: item,
}));

export const mappedCurrency = {
  "USD (US Dollar)": "$",
  "CAD (Canadian Dollar)": "CA$",
  "EUR (Euro)": "€",
  "AED (United Arab Emirates Dirham)": "AED",
  "AFN (Afghan Afghani)": "Af",
  "ALL (Albanian Lek)": "ALL",
  "AMD (Armenian Dram)": "AMD",
  "ARS (Argentine Peso)": "AR$",
  "AUD (Australian Dollar)": "AU$",
  "AZN (Azerbaijani Manat)": "man.",
  "BAM (Bosnia-Herzegovina Convertible Mark)": "KM",
  "BDT (Bangladeshi Taka)": "Tk",
  "BGN (Bulgarian Lev)": "BGN",
  "BHD (Bahraini Dinar)": "BD",
  "BIF (Burundian Franc)": "FBu",
  "BND (Brunei Dollar)": "BN$",
  "BOB (Bolivian Boliviano)": "Bs",
  "BRL (Brazilian Real)": "R$",
  "BWP (Botswanan Pula)": "BWP",
  "BYN (Belarusian Ruble)": "Br",
  "BZD (Belize Dollar)": "BZ$",
  "CDF (Congolese Franc)": "CDF",
  "CHF (Swiss Franc)": "CHF",
  "CLP (Chilean Peso)": "CL$",
  "CNY (Chinese Yuan)": "CN¥",
  "COP (Colombian Peso)": "CO$",
  "CRC (Costa Rican Colón)": "₡",
  "CVE (Cape Verdean Escudo)": "CV$",
  "CZK (Czech Republic Koruna)": "Kč",
  "DJF (Djiboutian Franc)": "Fdj",
  "DKK (Danish Krone)": "Dkr",
  "DOP (Dominican Peso)": "RD$",
  "DZD (Algerian Dinar)": "DA",
  "EEK (Estonian Kroon)": "Ekr",
  "EGP (Egyptian Pound)": "EGP",
  "ERN (Eritrean Nakfa)": "Nfk",
  "ETB (Ethiopian Birr)": "Br",
  "GBP (British Pound Sterling)": "£",
  "GEL (Georgian Lari)": "GEL",
  "GHS (Ghanaian Cedi)": "GH₵",
  "GNF (Guinean Franc)": "FG",
  "GTQ (Guatemalan Quetzal)": "GTQ",
  "HKD (Hong Kong Dollar)": "HK$",
  "HNL (Honduran Lempira)": "HNL",
  "HRK (Croatian Kuna)": "kn",
  "HUF (Hungarian Forint)": "Ft",
  "IDR (Indonesian Rupiah)": "Rp",
  "ILS (Israeli New Sheqel)": "₪",
  "INR (Indian Rupee)": "Rs",
  "IQD (Iraqi Dinar)": "IQD",
  "IRR (Iranian Rial)": "IRR",
  "ISK (Icelandic Króna)": "Ikr",
  "JMD (Jamaican Dollar)": "J$",
  "JOD (Jordanian Dinar)": "JD",
  "JPY (Japanese Yen)": "¥",
  "KES (Kenyan Shilling)": "Ksh",
  "KHR (Cambodian Riel)": "KHR",
  "KMF (Comorian Franc)": "CF",
  "KRW (South Korean Won)": "₩",
  "KWD (Kuwaiti Dinar)": "KD",
  "KZT (Kazakhstani Tenge)": "KZT",
  "LBP (Lebanese Pound)": "L.L.",
  "LKR (Sri Lankan Rupee)": "SLRs",
  "LTL (Lithuanian Litas)": "Lt",
  "LVL (Latvian Lats)": "Ls",
  "LYD (Libyan Dinar)": "LD",
  "MAD (Moroccan Dirham)": "MAD",
  "MDL (Moldovan Leu)": "MDL",
  "MGA (Malagasy Ariary)": "MGA",
  "MKD (Macedonian Denar)": "MKD",
  "MMK (Myanma Kyat)": "MMK",
  "MOP (Macanese Pataca)": "MOP$",
  "MUR (Mauritian Rupee)": "MURs",
  "MXN (Mexican Peso)": "MX$",
  "MYR (Malaysian Ringgit)": "RM",
  "MZN (Mozambican Metical)": "MTn",
  "NAD (Namibian Dollar)": "N$",
  "NGN (Nigerian Naira)": "₦",
  "NIO (Nicaraguan Córdoba)": "C$",
  "NOK (Norwegian Krone)": "Nkr",
  "NPR (Nepalese Rupee)": "NPRs",
  "NZD (New Zealand Dollar)": "NZ$",
  "OMR (Omani Rial)": "OMR",
  "PAB (Panamanian Balboa)": "B/.",
  "PEN (Peruvian Nuevo Sol)": "S/.",
  "PHP (Philippine Peso)": "₱",
  "PKR (Pakistani Rupee)": "PKRs",
  "PLN (Polish Zloty)": "zł",
  "PYG (Paraguayan Guarani)": "₲",
  "QAR (Qatari Rial)": "QR",
  "RON (Romanian Leu)": "RON",
  "RSD (Serbian Dinar)": "din.",
  "RUB (Russian Ruble)": "RUB",
  "RWF (Rwandan Franc)": "RWF",
  "SAR (Saudi Riyal)": "SR",
  "SDG (Sudanese Pound)": "SDG",
  "SEK (Swedish Krona)": "Skr",
  "SGD (Singapore Dollar)": "S$",
  "SOS (Somali Shilling)": "Ssh",
  "SYP (Syrian Pound)": "SY£",
  "THB (Thai Baht)": "฿",
  "TND (Tunisian Dinar)": "DT",
  "TOP (Tongan Paʻanga)": "T$",
  "TRY (Turkish Lira)": "TL",
  "TTD (Trinidad and Tobago Dollar)": "TT$",
  "TWD (New Taiwan Dollar)": "NT$",
  "TZS (Tanzanian Shilling)": "TSh",
  "UAH (Ukrainian Hryvnia)": "₴",
  "UGX (Ugandan Shilling)": "USh",
  "UYU (Uruguayan Peso)": "$U",
  "UZS (Uzbekistan Som)": "UZS",
  "VEF (Venezuelan Bolívar)": "Bs.F.",
  "VND (Vietnamese Dong)": "₫",
  "XAF (CFA Franc BEAC)": "FCFA",
  "XOF (CFA Franc BCEAO)": "CFA",
  "YER (Yemeni Rial)": "YR",
  "ZAR (South African Rand)": "R",
  "ZMK (Zambian Kwacha)": "ZK",
  "ZWL (Zimbabwean Dollar)": "ZWL$",
};

export const currencies = [
  {
    label: "USD (US Dollar)",
    value: "USD (US Dollar)",
  },
  {
    label: "CAD (Canadian Dollar)",
    value: "CAD (Canadian Dollar)",
  },
  {
    label: "EUR (Euro)",
    value: "EUR (Euro)",
  },
  {
    label: "AED (United Arab Emirates Dirham)",
    value: "AED (United Arab Emirates Dirham)",
  },
  {
    label: "AFN (Afghan Afghani)",
    value: "AFN (Afghan Afghani)",
  },
  {
    label: "ALL (Albanian Lek)",
    value: "ALL (Albanian Lek)",
  },
  {
    label: "AMD (Armenian Dram)",
    value: "AMD (Armenian Dram)",
  },
  {
    label: "ARS (Argentine Peso)",
    value: "ARS (Argentine Peso)",
  },
  {
    label: "AUD (Australian Dollar)",
    value: "AUD (Australian Dollar)",
  },
  {
    label: "AZN (Azerbaijani Manat)",
    value: "AZN (Azerbaijani Manat)",
  },
  {
    label: "BAM (Bosnia-Herzegovina Convertible Mark)",
    value: "BAM (Bosnia-Herzegovina Convertible Mark)",
  },
  {
    label: "BDT (Bangladeshi Taka)",
    value: "BDT (Bangladeshi Taka)",
  },
  {
    label: "BGN (Bulgarian Lev)",
    value: "BGN (Bulgarian Lev)",
  },
  {
    label: "BHD (Bahraini Dinar)",
    value: "BHD (Bahraini Dinar)",
  },
  {
    label: "BIF (Burundian Franc)",
    value: "BIF (Burundian Franc)",
  },
  {
    label: "BND (Brunei Dollar)",
    value: "BND (Brunei Dollar)",
  },
  {
    label: "BOB (Bolivian Boliviano)",
    value: "BOB (Bolivian Boliviano)",
  },
  {
    label: "BRL (Brazilian Real)",
    value: "BRL (Brazilian Real)",
  },
  {
    label: "BWP (Botswanan Pula)",
    value: "BWP (Botswanan Pula)",
  },
  {
    label: "BYN (Belarusian Ruble)",
    value: "BYN (Belarusian Ruble)",
  },
  {
    label: "BZD (Belize Dollar)",
    value: "BZD (Belize Dollar)",
  },
  {
    label: "CDF (Congolese Franc)",
    value: "CDF (Congolese Franc)",
  },
  {
    label: "CHF (Swiss Franc)",
    value: "CHF (Swiss Franc)",
  },
  {
    label: "CLP (Chilean Peso)",
    value: "CLP (Chilean Peso)",
  },
  {
    label: "CNY (Chinese Yuan)",
    value: "CNY (Chinese Yuan)",
  },
  {
    label: "COP (Colombian Peso)",
    value: "COP (Colombian Peso)",
  },
  {
    label: "CRC (Costa Rican Colón)",
    value: "CRC (Costa Rican Colón)",
  },
  {
    label: "CVE (Cape Verdean Escudo)",
    value: "CVE (Cape Verdean Escudo)",
  },
  {
    label: "CZK (Czech Republic Koruna)",
    value: "CZK (Czech Republic Koruna)",
  },
  {
    label: "DJF (Djiboutian Franc)",
    value: "DJF (Djiboutian Franc)",
  },
  {
    label: "DKK (Danish Krone)",
    value: "DKK (Danish Krone)",
  },
  {
    label: "DOP (Dominican Peso)",
    value: "DOP (Dominican Peso)",
  },
  {
    label: "DZD (Algerian Dinar)",
    value: "DZD (Algerian Dinar)",
  },
  {
    label: "EEK (Estonian Kroon)",
    value: "EEK (Estonian Kroon)",
  },
  {
    label: "EGP (Egyptian Pound)",
    value: "EGP (Egyptian Pound)",
  },
  {
    label: "ERN (Eritrean Nakfa)",
    value: "ERN (Eritrean Nakfa)",
  },
  {
    label: "ETB (Ethiopian Birr)",
    value: "ETB (Ethiopian Birr)",
  },
  {
    label: "GBP (British Pound Sterling)",
    value: "GBP (British Pound Sterling)",
  },
  {
    label: "GEL (Georgian Lari)",
    value: "GEL (Georgian Lari)",
  },
  {
    label: "GHS (Ghanaian Cedi)",
    value: "GHS (Ghanaian Cedi)",
  },
  {
    label: "GNF (Guinean Franc)",
    value: "GNF (Guinean Franc)",
  },
  {
    label: "GTQ (Guatemalan Quetzal)",
    value: "GTQ (Guatemalan Quetzal)",
  },
  {
    label: "HKD (Hong Kong Dollar)",
    value: "HKD (Hong Kong Dollar)",
  },
  {
    label: "HNL (Honduran Lempira)",
    value: "HNL (Honduran Lempira)",
  },
  {
    label: "HRK (Croatian Kuna)",
    value: "HRK (Croatian Kuna)",
  },
  {
    label: "HUF (Hungarian Forint)",
    value: "HUF (Hungarian Forint)",
  },
  {
    label: "IDR (Indonesian Rupiah)",
    value: "IDR (Indonesian Rupiah)",
  },
  {
    label: "ILS (Israeli New Sheqel)",
    value: "ILS (Israeli New Sheqel)",
  },
  {
    label: "INR (Indian Rupee)",
    value: "INR (Indian Rupee)",
  },
  {
    label: "IQD (Iraqi Dinar)",
    value: "IQD (Iraqi Dinar)",
  },
  {
    label: "IRR (Iranian Rial)",
    value: "IRR (Iranian Rial)",
  },
  {
    label: "ISK (Icelandic Króna)",
    value: "ISK (Icelandic Króna)",
  },
  {
    label: "JMD (Jamaican Dollar)",
    value: "JMD (Jamaican Dollar)",
  },
  {
    label: "JOD (Jordanian Dinar)",
    value: "JOD (Jordanian Dinar)",
  },
  {
    label: "JPY (Japanese Yen)",
    value: "JPY (Japanese Yen)",
  },
  {
    label: "KES (Kenyan Shilling)",
    value: "KES (Kenyan Shilling)",
  },
  {
    label: "KHR (Cambodian Riel)",
    value: "KHR (Cambodian Riel)",
  },
  {
    label: "KMF (Comorian Franc)",
    value: "KMF (Comorian Franc)",
  },
  {
    label: "KRW (South Korean Won)",
    value: "KRW (South Korean Won)",
  },
  {
    label: "KWD (Kuwaiti Dinar)",
    value: "KWD (Kuwaiti Dinar)",
  },
  {
    label: "KZT (Kazakhstani Tenge)",
    value: "KZT (Kazakhstani Tenge)",
  },
  {
    label: "LBP (Lebanese Pound)",
    value: "LBP (Lebanese Pound)",
  },
  {
    label: "LKR (Sri Lankan Rupee)",
    value: "LKR (Sri Lankan Rupee)",
  },
  {
    label: "LTL (Lithuanian Litas)",
    value: "LTL (Lithuanian Litas)",
  },
  {
    label: "LVL (Latvian Lats)",
    value: "LVL (Latvian Lats)",
  },
  {
    label: "LYD (Libyan Dinar)",
    value: "LYD (Libyan Dinar)",
  },
  {
    label: "MAD (Moroccan Dirham)",
    value: "MAD (Moroccan Dirham)",
  },
  {
    label: "MDL (Moldovan Leu)",
    value: "MDL (Moldovan Leu)",
  },
  {
    label: "MGA (Malagasy Ariary)",
    value: "MGA (Malagasy Ariary)",
  },
  {
    label: "MKD (Macedonian Denar)",
    value: "MKD (Macedonian Denar)",
  },
  {
    label: "MMK (Myanma Kyat)",
    value: "MMK (Myanma Kyat)",
  },
  {
    label: "MOP (Macanese Pataca)",
    value: "MOP (Macanese Pataca)",
  },
  {
    label: "MUR (Mauritian Rupee)",
    value: "MUR (Mauritian Rupee)",
  },
  {
    label: "MXN (Mexican Peso)",
    value: "MXN (Mexican Peso)",
  },
  {
    label: "MYR (Malaysian Ringgit)",
    value: "MYR (Malaysian Ringgit)",
  },
  {
    label: "MZN (Mozambican Metical)",
    value: "MZN (Mozambican Metical)",
  },
  {
    label: "NAD (Namibian Dollar)",
    value: "NAD (Namibian Dollar)",
  },
  {
    label: "NGN (Nigerian Naira)",
    value: "NGN (Nigerian Naira)",
  },
  {
    label: "NIO (Nicaraguan Córdoba)",
    value: "NIO (Nicaraguan Córdoba)",
  },
  {
    label: "NOK (Norwegian Krone)",
    value: "NOK (Norwegian Krone)",
  },
  {
    label: "NPR (Nepalese Rupee)",
    value: "NPR (Nepalese Rupee)",
  },
  {
    label: "NZD (New Zealand Dollar)",
    value: "NZD (New Zealand Dollar)",
  },
  {
    label: "OMR (Omani Rial)",
    value: "OMR (Omani Rial)",
  },
  {
    label: "PAB (Panamanian Balboa)",
    value: "PAB (Panamanian Balboa)",
  },
  {
    label: "PEN (Peruvian Nuevo Sol)",
    value: "PEN (Peruvian Nuevo Sol)",
  },
  {
    label: "PHP (Philippine Peso)",
    value: "PHP (Philippine Peso)",
  },
  {
    label: "PKR (Pakistani Rupee)",
    value: "PKR (Pakistani Rupee)",
  },
  {
    label: "PLN (Polish Zloty)",
    value: "PLN (Polish Zloty)",
  },
  {
    label: "PYG (Paraguayan Guarani)",
    value: "PYG (Paraguayan Guarani)",
  },
  {
    label: "QAR (Qatari Rial)",
    value: "QAR (Qatari Rial)",
  },
  {
    label: "RON (Romanian Leu)",
    value: "RON (Romanian Leu)",
  },
  {
    label: "RSD (Serbian Dinar)",
    value: "RSD (Serbian Dinar)",
  },
  {
    label: "RUB (Russian Ruble)",
    value: "RUB (Russian Ruble)",
  },
  {
    label: "RWF (Rwandan Franc)",
    value: "RWF (Rwandan Franc)",
  },
  {
    label: "SAR (Saudi Riyal)",
    value: "SAR (Saudi Riyal)",
  },
  {
    label: "SDG (Sudanese Pound)",
    value: "SDG (Sudanese Pound)",
  },
  {
    label: "SEK (Swedish Krona)",
    value: "SEK (Swedish Krona)",
  },
  {
    label: "SGD (Singapore Dollar)",
    value: "SGD (Singapore Dollar)",
  },
  {
    label: "SOS (Somali Shilling)",
    value: "SOS (Somali Shilling)",
  },
  {
    label: "SYP (Syrian Pound)",
    value: "SYP (Syrian Pound)",
  },
  {
    label: "THB (Thai Baht)",
    value: "THB (Thai Baht)",
  },
  {
    label: "TND (Tunisian Dinar)",
    value: "TND (Tunisian Dinar)",
  },
  {
    label: "TOP (Tongan Paʻanga)",
    value: "TOP (Tongan Paʻanga)",
  },
  {
    label: "TRY (Turkish Lira)",
    value: "TRY (Turkish Lira)",
  },
  {
    label: "TTD (Trinidad and Tobago Dollar)",
    value: "TTD (Trinidad and Tobago Dollar)",
  },
  {
    label: "TWD (New Taiwan Dollar)",
    value: "TWD (New Taiwan Dollar)",
  },
  {
    label: "TZS (Tanzanian Shilling)",
    value: "TZS (Tanzanian Shilling)",
  },
  {
    label: "UAH (Ukrainian Hryvnia)",
    value: "UAH (Ukrainian Hryvnia)",
  },
  {
    label: "UGX (Ugandan Shilling)",
    value: "UGX (Ugandan Shilling)",
  },
  {
    label: "UYU (Uruguayan Peso)",
    value: "UYU (Uruguayan Peso)",
  },
  {
    label: "UZS (Uzbekistan Som)",
    value: "UZS (Uzbekistan Som)",
  },
  {
    label: "VEF (Venezuelan Bolívar)",
    value: "VEF (Venezuelan Bolívar)",
  },
  {
    label: "VND (Vietnamese Dong)",
    value: "VND (Vietnamese Dong)",
  },
  {
    label: "XAF (CFA Franc BEAC)",
    value: "XAF (CFA Franc BEAC)",
  },
  {
    label: "XOF (CFA Franc BCEAO)",
    value: "XOF (CFA Franc BCEAO)",
  },
  {
    label: "YER (Yemeni Rial)",
    value: "YER (Yemeni Rial)",
  },
  {
    label: "ZAR (South African Rand)",
    value: "ZAR (South African Rand)",
  },
  {
    label: "ZMK (Zambian Kwacha)",
    value: "ZMK (Zambian Kwacha)",
  },
  {
    label: "ZWL (Zimbabwean Dollar)",
    value: "ZWL (Zimbabwean Dollar)",
  },
];

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const standardSlotTimings = [
  "10:00 AM",
  "10:40 AM",
  "11:20 AM",
  "12:00 PM",
  "12:40 PM",
  "01:20 PM",
  "02:00 PM",
  "02:40 PM",
  "03:20 PM",
  "04:00 PM",
  "04:40 PM",
  "05:20 PM",
  "06:00 PM",
  "06:40 PM",
  "07:20 PM",
  "08:00 PM",
];

export const categories = [
  { key: "Fruits", value: "Fruits" },
  { key: "Vegetables", value: "Vegetables" },
  { key: "Vehicles", value: "Vehicles" },
  { key: "Snacks", value: "Snacks" },
  { key: "Common Object", value: "Common Object" },
  { key: "Food", value: "Food" },
  { key: "Background", value: "Background" },
  { key: "Prepositions", value: "Prepositions" },
  { key: "Adjectives", value: "Adjectives" },
  { key: "Verbs", value: "Verbs" },
  { key: "Transparent Pics", value: "Transparent Pics" },
  { key: "Cards", value: "Cards" },
  { key: "Animals", value: "Animals" },
  { key: "Place", value: "Place" },
  { key: "Occupation", value: "Occupation" },
  { key: "People", value: "People" },
  { key: "Body Parts", value: "Body Parts" },
  { key: "Colours", value: "Colours" },
  { key: "Shapes", value: "Shapes" },
  { key: "Emotions", value: "Emotions" },
  { key: "Articulation", value: "Articulation" },
];

export const categoriesLabel = [
  { label: "Fruits", value: "Fruits" },
  { label: "Vegetables", value: "Vegetables" },
  { label: "Vehicles", value: "Vehicles" },
  { label: "Snacks", value: "Snacks" },
  { label: "Common Object", value: "Common Object" },
  { label: "Food", value: "Food" },
  { label: "Background", value: "Background" },
  { label: "Prepositions", value: "Prepositions" },
  { label: "Adjectives", value: "Adjectives" },
  { label: "Verbs", value: "Verbs" },
  { label: "Transparent Pics", value: "Transparent Pics" },
  { label: "Cards", value: "Cards" },
  { label: "Animals", value: "Animals" },
  { label: "Place", value: "Place" },
  { label: "Occupation", value: "Occupation" },
  { label: "People", value: "People" },
  { label: "Body Parts", value: "Body Parts" },
  { label: "Colours", value: "Colours" },
  { label: "Shapes", value: "Shapes" },
  { label: "Emotions", value: "Emotions" },
  { label: "Articulation", value: "Articulation" },
];
export const category = [
  "All",
  "Fruits",
  "Vegetables",
  "Vehicles",
  "Snacks",
  "Common Object",
  "Food",
  "Background",
  "Prepositions",
  "Adjectives",
  "Verbs",
  "Transparent pics",
  "Cards",
  "Animals",
  "Place",
  "Occupation",
  "People",
  "Body Parts",
  "Colours",
  "Shapes",
  "Emotions",
  "Articulation",
];

export let initialSlotData = {
  Sunday: [{ start_time: "", end_time: "" }],
  Monday: [{ start_time: "", end_time: "" }],
  Tuesday: [{ start_time: "", end_time: "" }],
  Wednesday: [{ start_time: "", end_time: "" }],
  Thursday: [{ start_time: "", end_time: "" }],
  Friday: [{ start_time: "", end_time: "" }],
  Saturday: [{ start_time: "", end_time: "" }],
};

export let initialScheduleSlotData = {
  Sunday: [],
  Monday: [],
  Tuesday: [],
  Wednesday: [],
  Thursday: [],
  Friday: [],
  Saturday: [],
};

export const genderOptions = [
  {
    key: "Please select",
    value: "",
  },
  {
    key: "Male",
    value: "Male",
  },
  {
    key: "Female",
    value: "Female",
  },
  {
    key: "Other",
    value: "Other",
  },
];

export const specializedInOptions = [
  {
    key: "Please select",
    value: "",
  },
  {
    key: "Speech",
    value: "Speech",
  },
  {
    key: "Occupational Therapy",
    value: "Occupational Therapy",
  },
  {
    key: "Special Education",
    value: "Special Education",
  },
  {
    key: "Child Psychology",
    value: "Child Psychology",
  },
];
export const specializedInOptionsForMultiple = [
  {
    label: "Please select",
    value: "",
  },
  {
    label: "Speech",
    value: "Speech",
  },
  {
    label: "Occupational Therapy",
    value: "Occupational Therapy",
  },
  {
    label: "Special Education",
    value: "Special Education",
  },
  {
    label: "Child Psychology",
    value: "Child Psychology",
  },
];

export const speechConcern = [
  { label: "Unclear Speech", value: "Unclear Speech" },
  { label: "Delayed Speech", value: "Delayed Speech" },
  { label: "Stammering/Stuttering", value: "Stammering/Stuttering" },
  { label: "Other", value: "other" },
];

export const otConcern = [
  { label: "Occupational Therapy", value: "Occupational Therapy" },
];
export const specialEdConcern = [
  { label: "Special Education", value: "Special Education" },
];
export const childPsychologyConcern = [
  {
    label: "Child Psychology",
    value: "Child Psychology",
  },
];

export const concernOptions = {
  Speech: speechConcern,
  "Occupational Therapy": otConcern,
  "Special Education": specialEdConcern,
  "Child Psychology": childPsychologyConcern,
};

export const monthOptions = [
  {
    key: "Select Demo Month",
    value: "",
  },
  {
    key: "January",
    value: 1,
  },
  {
    key: "February",
    value: 2,
  },
  {
    key: "March",
    value: 3,
  },
  {
    key: "April",
    value: 4,
  },
  {
    key: "May",
    value: 5,
  },
  {
    key: "June",
    value: 6,
  },
  {
    key: "July",
    value: 7,
  },
  {
    key: "August",
    value: 8,
  },
  {
    key: "September",
    value: 9,
  },
  {
    key: "October",
    value: 10,
  },
  {
    key: "November",
    value: 11,
  },
  {
    key: "December",
    value: 12,
  },
];

export const leadStatusOptions = [
  { key: "Select Status", value: "" },
  { key: "Created", value: "Created" },
  {
    key: "Not Contactable",
    value: "Not Contactable",
  },
  {
    key: "Contact Later",
    value: "Contact Later",
  },
  {
    key: "Demo Scheduled",
    value: "Demo Scheduled",
  },
  { key: "Demo Given", value: "Demo Given" },
  {
    key: "On Hold",
    value: "On Hold",
  },
  {
    key: "Offer Given",
    value: "Offer Given",
  },
  {
    key: "Dropped",
    value: "Dropped",
  },
  {
    key: "Payment Received",
    value: "Payment Received",
  },
  {
    key: "Converted",
    value: "Converted",
  },
];
export const companyStatusOptions = [
  { key: "Select Status", value: "" },
  { key: "Active", value: "Active" },
  { key: "Dropped", value: "Dropped" },
  { key: "Break", value: "Break" },
];

export const numToDays = {
  1: "Sunday",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Saturday",
};
export const daysToNum = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7,
};
