import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CSubheader,
  CBreadcrumbRouter,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

// routes config
import clientRoutes from "../routes/clientRoutes";
import adminRoutes from "src/routes/adminRoutes";
import therapistRoutes from "src/routes/therapistRoutes";

// import { TheHeaderDropdown } from "./index";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from "react-router";
import partnerRoutes from "src/routes/partnerRoutes";
import companyRoutes from "src/routes/companyRoutes";
import TheHeaderDropdown from "./TheHeaderDropdown";

const TheHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sidebarShow = useSelector((state) => state.commonReducer.sidebarShow);
  const { role } = useSelector((state) => state?.auth?.userDetails);
  const { name } = useSelector((state) => state.auth.userDetails);

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  let routes =
    role === "super_admin"
      ? adminRoutes
      : role === "company"
      ? companyRoutes
      : role === "therapist"
      ? therapistRoutes
      : role === "partner"
      ? partnerRoutes
      : clientRoutes;

  return (
    <>
      <CHeader withSubheader>
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={toggleSidebarMobile}
        />
        <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={toggleSidebar}
        />
        {/* <span
          className="d-flex mb-2"
          style={{
            textDecoration: "none",
            fontWeight: "600",
            cursor: "pointer",
          }}
          onClick={() => {
            history.goBack();
          }}
        >
          <AiOutlineArrowLeft
            style={{
              fontSize: "24px",
              marginTop: "15px",
              marginLeft: "15px",
              fontWeight: "bold",
              border: "grey 0.5px solid",
              borderRadius: "50%",
              padding: "3px",
            }}
          />
        </span> */}

        {/* <CHeaderBrand className="mx-auto d-lg-none" to="/">
          <CIcon name="logo" height="48" alt="Logo" />
        </CHeaderBrand> */}

        <CHeaderNav className="d-md-down-none mr-auto"></CHeaderNav>

        <CHeaderNav className="px-3 ml-auto">
          <TheHeaderDropdown />
        </CHeaderNav>

        <CSubheader className="px-3 justify-content-between">
          <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-0 px-md-3"
            routes={routes}
          />
          <p className="my-3 text-uppercase mx-4">
            {role === "therapist" ? name : role?.replace("_", " ")}

            {/* {role?.replace("_", " ")} */}
          </p>
        </CSubheader>
      </CHeader>
    </>
  );
};

export default TheHeader;
