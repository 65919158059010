import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInputGroup,
  CRow,
  CInput,
  CInputGroupPrepend,
  CInputGroupText,
} from "@coreui/react";
import Images from "src/constants/images/1.svg";
import { changePassword, resetPassword } from "./apis";
import { SET_LOADER } from "src/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import PasswordInput from "src/components/formFields/passwordInput";
import localStorageConstants from "src/constants/localstorageConstants";
import validations from "src/utils/validation";
import LoginPasswordInput from "src/components/LoginInput/LoginPasswordInput";
import logoImage from "src/assets/icons/phonologix2.png";
import phonoImage from "src/constants/images/Group (1).png";
import "./style.css";
import { SET_USER_DATA } from "../actions";
import { logout } from "src/utils/system";

const ResetPassword = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const { type } = useParams();
  const { state: { userId: user_id } = {} } = useLocation();
  const [error, setError] = useState("");

  const user = localStorage.getItem(localStorageConstants.userId);
  const { _id } = useSelector((state) => state?.auth?.userDetails);

  let userId = _id || user || user_id;

  useEffect(() => {
    if ((type !== "change" && !userId) || (type === "change" && !user)) {
      history.push("/login");
    }
  }, []);

  const onClickResetPassword = () => {
    if (newPassword !== confirmNewPassword) {
      toast.error("The two passwords does not match");
      return;
    }
    if (
      !validations.password.test(newPassword) ||
      !validations.password.test(confirmNewPassword)
    ) {
      toast.error(
        "Password must contain atleast 8 characters, one lowercase letter, one uppercase letter and special character"
      );
      return;
    }

    dispatch({ type: SET_LOADER, payload: true });
    if (type === "change") {
      changePassword(
        {
          data: {
            oldPassword: oldPassword,
            newPassword: newPassword,
            user_id: userId,
          },
        },
        (data) => {
          if (data?.status === "success") {
            history.push("/dashboard");
            toast.success("Password has been changed");
            dispatch({ type: SET_LOADER, payload: false });
          } else {
            dispatch({ type: SET_LOADER, payload: false });
            toast.error(data?.message);
          }
        }
      );
    } else {
      resetPassword(
        { data: { newPassword: newPassword, user_id: userId } },
        (data) => {
          if (data?.status === "success") {
            toast.success("Password has been resetted");
            dispatch({ type: SET_LOADER, payload: false });
            dispatch({ type: SET_USER_DATA, payload: data?.data });
            history.push("/dashboard");
          } else {
            dispatch({ type: SET_LOADER, payload: false });
            toast.error(data?.message);
          }
        }
      );
    }
  };

  return (
    <>
      <div className="c-app c-default-layout login-background">
        <CCol lg="7" md="7">
          <div>
            <img src={logoImage} alt="img" className="logoImage" />
          </div>
        </CCol>
        <CCol lg="5" md="5" xs="12">
          <div>
            <img src={phonoImage} alt="img" className="phonoImage" />
          </div>
          <div className="form-box">
            <h1 className="login-text mb-5">
              {" "}
              {type === "change" ? "Change" : "Reset"} Password
            </h1>
            <div className="login-box">
              {type === "change" ? (
                <LoginPasswordInput
                  value={oldPassword}
                  setValue={setOldPassword}
                  placeholder="Password"
                  inputClassName="p-3 input-box"
                />
              ) : null}
              <LoginPasswordInput
                value={newPassword}
                setValue={setNewPassword}
                placeholder="New Password"
                inputClassName="p-3 input-box"
              />
              <LoginPasswordInput
                value={confirmNewPassword}
                setValue={setConfirmNewPassword}
                placeholder="Confirm New Password"
                inputClassName="p-3 input-box"
              />
              <div className="text-center mt-2">
                <p style={{ textAlign: "center", width: 400 }}>
                  Note:- Password must contain atleast 8 characters, one
                  lowercase letter, one uppercase letter, special character and
                  no space in between
                </p>
              </div>
            </div>
            <div className="text-danger mb-4">{error}</div>
            <CRow>
              <CCol xs="6">
                <button onClick={onClickResetPassword} className="btnn-txt">
                  {type === "change" ? "Change" : "Reset"}
                </button>
              </CCol>
            </CRow>
          </div>
        </CCol>
      </div>
      <button
        onClick={() => {
          dispatch(logout());
        }}
        className="btn btn-danger "
        style={{
          top: "14px",
          position: "absolute",
          right: "14px",
        }}
      >
        Logout
      </button>
    </>
  );
};

export default ResetPassword;
