import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { icons } from "./assets/icons";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Loading } from "./App";
import { SnackbarProvider } from "notistack";
import "./i18n";

React.icons = icons;

ReactDOM.render(
  <SnackbarProvider maxSnack={3}>
    <Provider store={store}>
      <BrowserRouter>
        <React.Suspense fallback={() => <Loading loading={true} />}>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </React.Suspense>
      </BrowserRouter>
    </Provider>
  </SnackbarProvider>,
  document.getElementById("root"),
  document.getElementById("root").addEventListener("contextmenu", (e) => {
    e.preventDefault();
  })
);

{
  /* <Provider store={store}>
        <Suspense fallback={<LoaderOne loading={true} />}>
          <BrowserRouter basename="/genwe-portal/">
            <Switch>
              <Routes />
              <Loader />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </Provider> */
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
