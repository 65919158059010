const redirect = () => {
  const url = window.location.host;
  const original = {
    democonsultation: "democonsultation",
    screening: "screening",
    demogoals: "demogoals",
  };
  const splittedSubDomain = url.split(".");
  if (splittedSubDomain?.length === 3) {
    if (splittedSubDomain?.[0] === original.democonsultation) {
      window.location.href = "https://portal.speechally.in/session/book";
    }
    if (splittedSubDomain?.[0] === original.screening) {
      window.location.href = "https://portal.speechally.in/screening";
    }
    if (splittedSubDomain?.[0] === original.demogoals) {
      window.location.href = "https://portal.speechally.in/demogoals";
    }
  }
};

export default redirect;
