import React from "react";
import { CCardHeader } from "@coreui/react";

const MainHeading = ({
  heading = "",
  headingSize = "large",
  renderRight = () => {},
  style = {},
}) => {
  return (
    <CCardHeader
      className="mainheading-body"
      style={{
        border: "none",
        paddingBottom: "0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 15,
      }}
    >
      {headingSize === "large" ? (
        <h3 style={{ ...style, marginBottom: 0 }}>{heading}</h3>
      ) : (
        <h5 style={{ ...style, marginBottom: 0 }}>{heading}</h5>
      )}
      {renderRight()}
    </CCardHeader>
  );
};

export default MainHeading;
