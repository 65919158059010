import { CFade } from "@coreui/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import authRoutes from "./routes/authRoutes";
import MobileNullScreen from "./pages/mobileNullScreen";
import sessionRoutes from "./routes/sessionRoutes";
import "./scss/style.scss";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/loader";
import clientRoutes from "./routes/clientRoutes";
import CustomModal from "./components/modal";
import { SET_SHOW_COMMON_MODAL } from "./redux/actions";
import { isSubscribed } from "./utils/system";
import redirect from "./utils/redirect";
import { MediaRecorder, register } from "extendable-media-recorder";
import { connect, disconnect } from "extendable-media-recorder-wav-encoder";
import TheLayout from "./containers/TheLayout";

const Loading = ({ loading }) => {
  console.log("loading");
  if (!loading) {
    return null;
  }
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
};

const TheGoalsLayout = React.lazy(() => import("./containers/TheGoalsLayout"));

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.commonReducer.loader);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    (async () => {
      const id = await connect();
      await register(id);
    })();

    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (console.log && process.env.NODE_ENV !== "development") {
      console.log = () => {};
    }
    redirect();
  }, []);

  const commonModalContent = useSelector(
    (state) => state.commonReducer.commonModalContent
  );
  const isCommonModalVisible = useSelector(
    (state) => state.commonReducer.isCommonModalVisible
  );
  const {
    _id,
    role,
    is_subscription_appointments_scheduled = false,
    is_password_resetted,
  } = useSelector((state) => state?.auth?.userDetails);

  if (
    role === "client" &&
    !isSubscribed() &&
    !window.location.pathname?.includes("/client/subscribe") &&
    !window.location.pathname?.includes("/password/reset")
  ) {
    return <Redirect to="/client/subscribe" />;
  }

  if (
    role === "client" &&
    isSubscribed() &&
    !window.location.pathname?.includes("/client/schedule") &&
    !is_subscription_appointments_scheduled
  ) {
    return <Redirect to="/client/schedule" />;
  }

  if (
    role === "partner" &&
    !is_password_resetted &&
    !window.location.pathname?.includes("/password/reset")
  ) {
    return <Redirect to="/password/reset" />;
  }

  return (
    <>
      {windowWidth < 768 && role === "client" ? (
        <MobileNullScreen />
      ) : (
        <>
          <Loader loading={loading} />

          <ToastContainer
            position="bottom-right"
            autoClose={10000}
            hideProgressBar
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{ zIndex: 99999999 }}
          />

          <Switch>
            {authRoutes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <CFade>
                        <route.component {...props} />
                      </CFade>
                    )}
                  />
                )
              );
            })}
            {clientRoutes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <CFade>
                        <route.component {...props} />
                      </CFade>
                    )}
                  />
                )
              );
            })}
            {sessionRoutes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <CFade>
                        <route.component {...props} />
                      </CFade>
                    )}
                  />
                )
              );
            })}

            <Route
              path="/userActivity/"
              name="User Activity"
              render={(props) => <TheGoalsLayout {...props} />}
            />

            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
          <CustomModal
            setIsModalVisible={() => {
              dispatch({ type: SET_SHOW_COMMON_MODAL, payload: false });
            }}
            title={commonModalContent?.title}
            innerText={commonModalContent?.innerText}
            isModalVisible={isCommonModalVisible}
            primaryCtaFunction={() => {
              dispatch({ type: SET_SHOW_COMMON_MODAL, payload: false });
            }}
            primaryText={commonModalContent?.primaryText}
          />
        </>
      )}
    </>
  );
  // }
};

export default App;

export { Loading };
