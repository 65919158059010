import axios from "axios";
import { apiBaseUrl } from "./apis";
import axiosRetry from "axios-retry";
import { SET_LOADER } from "src/redux/actions";
import { toast } from "react-toastify";
import { store } from "src/redux/store";
import localStorageConstants from "src/constants/localstorageConstants";
import crypto from "crypto-js";

const apiInstance = () => {
  const api = axios.create({
    baseURL: apiBaseUrl,
  });
  axiosRetry(api, { retries: 3 });

  api.interceptors.request.use(async (config) => {
    let accessToken = localStorage.getItem(localStorageConstants.accessToken);
    if (accessToken) {
      if (config.method !== "OPTIONS") {
        config.headers["x-access-token"] = accessToken;
      }
    }
    config.headers["api-key"] = "ec5670ac-1c74-4f3c-932a-128632adbde8";
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      if (response?.data) {
        let newResponse = { ...response };
        const bytes = crypto.AES.decrypt(
          response?.data,
          process.env.REACT_APP_SECRET_KEY
        );
        const decryptedData = bytes.toString(crypto.enc.Utf8);

        newResponse.data = decryptedData?.includes("{")
          ? JSON.parse(decryptedData)
          : decryptedData;
        return newResponse;
      } else {
        return response;
      }
    },
    (error) => {
      if (error?.response?.data) {
        let newError = { ...error };
        const bytes = crypto.AES.decrypt(
          error?.response?.data,
          process.env.REACT_APP_SECRET_KEY
        );
        const decryptedData = bytes.toString(crypto.enc.Utf8);
        newError.response.data = decryptedData?.includes("{")
          ? JSON.parse(decryptedData)
          : decryptedData;
        console.log(newError, "Errr");
        console.log(newError?.response, "err?.response");
        console.log(newError?.data, "err?.data");
        console.log(newError?.response?.data, "err?.response?.data");
        if (newError?.response?.data?.err?.message === "userId is required") {
          store.dispatch({ type: SET_LOADER, payload: false });
          return;
        }
        if (newError?.message) {
          store.dispatch({ type: SET_LOADER, payload: false });
          toast.error(newError?.response?.data?.err?.message);
          return;
        }
        if (newError?.response?.data?.err?.message) {
          store.dispatch({ type: SET_LOADER, payload: false });
          toast.error(newError?.response?.data?.err?.message);
          return;
        }
        if (newError?.response?.data?.message) {
          store.dispatch({ type: SET_LOADER, payload: false });
          toast.error(newError?.response?.data?.message);
          return;
        }
        throw newError;
      } else {
        throw error;
      }
    }
  );

  return api;
};

const apiClient = apiInstance();
apiClient.defaults.headers.post["user"] = localStorage.getItem("userId");
apiClient.defaults.headers.post["language"] =
  localStorage.getItem("i18nextLng");

export default apiClient;
