import { useEffect } from "react";
//import Select from "../formFields/select";
import Select from "react-select";
import useMutation from "src/hooks/useMutation";
import { useDispatch } from "react-redux";
import { CLabel } from "@coreui/react";

const DynamicSelectInput = ({
  containerStyle = {},
  url,
  initialKey,
  title,
  showAll = false,
  postData = {},
  objectKey,
  secondObjectKey,
  thirdObjectKey,
  valueKey,
  minWidth,
  style = {},
  styleTitle = {},
  value,
  required,
  noLoading = false,
  isFlex = true,
  className,
  isEditable = true,
  id,
  error,
  disabled = false,
  showLeadName = false,
  placeholder = "Select",
  ...rest
}) => {
  const dispatch = useDispatch();
  let { data = [], getData, loading } = useMutation(true);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      ...style,
    }),
  };

  useEffect(() => {
    getData({ url, postData: postData || {} });
  }, [postData]);

  let labelOptions = [];
  let labelOptionsTemp = {};

  if (thirdObjectKey) {
    data?.forEach((item) => {
      labelOptionsTemp[item?.[thirdObjectKey]] = [
        ...(labelOptionsTemp?.[item?.[thirdObjectKey]] || []),
        { label: item?.[objectKey], value: item?.[valueKey] },
      ];
    });

    labelOptions = Object.keys(labelOptionsTemp)?.map((it) => {
      return { label: it, options: labelOptionsTemp?.[it] };
    });
  }

  let options = thirdObjectKey
    ? labelOptions
    : objectKey
    ? [
        { label: initialKey, value: initialKey },
        ...data?.map((item) => {
          return {
            label: secondObjectKey
              ? `${item?.[objectKey]} (${item?.[secondObjectKey]})`
              : item?.[objectKey],
            value: item?.[valueKey],
          };
        }),
      ]
    : [
        { label: initialKey, value: initialKey },
        ...data?.map((item) => {
          return { label: item, value: item };
        }),
      ];

  if (showAll) {
    options.shift();
    options.unshift(
      { label: initialKey, value: initialKey },
      { label: "All", value: "All" }
    );
  }

  if (!initialKey) {
    options.shift();
  }

  console.log(options, "options");

  // return <Select {...rest} options={options} />;
  return (
    <div
      style={containerStyle}
      className={`d-flex gap-1 flex-column ${isFlex && "flex-sm-row"}`}
    >
      {title ? (
        <h6 className="mt-2 mr-2" style={styleTitle}>
          {title}
          {required ? <span style={{ color: "red" }}> *</span> : null}
        </h6>
      ) : (
        ""
      )}

      {isEditable ? (
        <div className={className || "w-100"}>
          <Select
            {...rest}
            isDisabled={disabled}
            options={options}
            styles={customStyles}
            placeholder={placeholder}
            value={
              rest?.isMulti
                ? value(
                    data?.map((it) => ({
                      label: it?.[objectKey],
                      value: it?.[valueKey],
                    }))
                  )
                : options?.find((item) => item?.value === value)
            }
          />
        </div>
      ) : (
        <div style={{ fontSize: 16.5, fontWeight: 500 }}>
          {showLeadName ? "Lead Name: " : ""}
          {data?.length
            ? options?.find((item) => item?.value === value)?.label
              ? options?.find((item) => item?.value === value)?.label
              : value
              ? value(
                  data?.map((it) => ({
                    label: it?.[objectKey],
                    value: it?.[valueKey],
                  }))
                )
                  ?.map((item) => {
                    return item?.label;
                  })
                  ?.join(", ")
              : "_"
            : showLeadName
            ? "Loading..."
            : "-"}
        </div>
      )}
      {error ? (
        <CLabel htmlFor={id} style={{ color: "#e55353", marginBottom: 0 }}>
          {error}
        </CLabel>
      ) : null}
    </div>
  );
};

export default DynamicSelectInput;
