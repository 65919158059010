import React from "react";
import "./index.css";
import logo from "../../assets/icons/logo.png";
import AppStoreIcon from "../../assets/icons/appStore.png";
import playStoreIcon from "../../assets/icons/playStore.png";
const MobileNullScreen = () => {
  return (
    <div className="main-container">
      <div style={{ marginTop: "50%", transform: "translateY(-50%)" }}>
        <img src={logo} alt="" style={{ maxWidth: "250px" }} />
      </div>
      <p className="firstTxt">
        To Experience Activities, Goals and other amazing things on a small
        screen
      </p>
      <p className="secondTxt">Download our mobile app!</p>

      <div className="link-container">
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.phonologixapp&hl=en&gl=US"
        >
          <img src={playStoreIcon} alt="play store icon" />
        </a>
        <a
          target="_blank"
          href="https://apps.apple.com/in/app/speech-ally-app/id6450113004"
        >
          <img src={AppStoreIcon} alt="app store icon" />
        </a>
      </div>
    </div>
  );
};

export default MobileNullScreen;
