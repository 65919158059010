import React, { useState } from "react";
import {
  CInputGroup,
} from "@coreui/react";
import "./style.css"
const LoginPasswordInput = ({
  value,
  setValue,
  title = "Password",
  placeholder = "Password",
  className = "",
  inputClassName="",
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <CInputGroup className={className}>
    <div className="password-input-box">
      <input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        type={showPassword ? "" : "password"}
        placeholder={placeholder}
        autoComplete="current-password"
        className={inputClassName}
      ></input>
        
         
      <div
        key={showPassword}
        onClick={() => setShowPassword(!showPassword)}
        style={{
          cursor: "pointer",
          position:"absolute",
          left:"370px",
          top:"15px"
        }}
      >
        <i className={`fas ${!showPassword ?"fa-eye-slash"  : "fa-eye"}`}></i>
      </div>
      </div>
    </CInputGroup>
  );
};

export default LoginPasswordInput;
