import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CFormGroup, CCol } from "@coreui/react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Form, Formik } from "formik";
import SimpleInput from "src/components/formFields/simpleInput";
import SimpleButton from "src/components/buttons/simpleButton";
import { useHistory, useParams } from "react-router-dom";
import Select from "src/components/formFields/select";
// import Actions from "./actions";
import { isAndroid, isIOS } from "react-device-detect";
import apiUrls from "src/apis/apis";
import useMutation from "src/hooks/useMutation";
import { genderOptions } from "src/utils/constants";
import DatePicker from "src/components/formFields/datePicker";
import moment from "moment";
import { toast } from "react-toastify";
function AddRemark() {
  const [remarkDetails, setRemarkDetails] = useState({});
  const history = useHistory();
  const { leadId } = useParams();
  const { getData: getRemarks } = useMutation();
  const { getData: addRemarks } = useMutation();
  const { remark = "", date = "", status = "Created" } = remarkDetails || {};
  return (
    <>
      <CCard>
        <Formik
          enableReinitialize
          initialValues={{
            date,
            remark,
            status,
          }}
          onSubmit={(values) => {
            addRemarks({
              url: apiUrls.remarks.add,
              postData: {
                data: {
                  lead_id: leadId,
                  ...values,
                },
              },
              onSuccess: () => {
                toast.success(`added successfully`);
                history.push(`/remarks/list/${leadId}`);
              },
            });
          }}
        >
          {({ errors, touched, values, setFieldValue }) => {
            return (
              <Form>
                {leadId && (
                  <CCardBody>
                    <CFormGroup row className="mt-3">
                      <CCol lg="6" md="6">
                        <Select
                          title="Status"
                          onChange={(e) => {
                            setFieldValue("status", e.target.value);
                          }}
                          value={values?.status}
                          error={touched?.status && errors?.status}
                          required
                          options={[
                            { key: "Please select", value: "" },
                            { key: "Created", value: "Created" },
                            {
                              key: "Not Contactable",
                              value: "Not Contactable",
                            },
                            {
                              key: "Contact Later",
                              value: "Contact Later",
                            },
                            {
                              key: "Demo Scheduled",
                              value: "Demo Scheduled",
                            },
                            { key: "Demo Given", value: "Demo Given" },
                            {
                              key: "On Hold",
                              value: "On Hold",
                            },
                            {
                              key: "Offer Given",
                              key: "Offer Given",
                            },
                            {
                              key: "Dropped",
                              key: "Dropped",
                            },
                            {
                              key: "Payment Received",
                              key: "Payment Received",
                            },
                          ]}
                        />
                      </CCol>

                      <CCol lg="6" md="6">
                        <SimpleInput
                          title="Remark"
                          placeholder="Enter Remark"
                          onChange={(e) => {
                            setFieldValue("remark", e.target.value);
                          }}
                          date
                          value={values?.remark}
                          required
                        />
                      </CCol>
                    </CFormGroup>
                    {/* <CFormGroup>
                      <CCol lg="6" style={{ marginLeft: 0, paddingLeft: 0 }}>
                        <DatePicker
                          title="Date"
                          placeholder="Enter Date"
                          onChange={(e) => {
                            setFieldValue("date", e.target.value);
                          }}
                          value={
                            isIOS
                              ? values?.date
                                ? moment(values?.date)
                                : ""
                              : values?.date
                              ? moment(values?.date)?.format("DD/MMM/YYYY")
                              : ""
                          }
                          error={touched?.date && errors?.date}
                          required
                          style={
                            isIOS
                              ? {
                                  // textAlign: "left",
                                  //   paddingLeft: -20,
                                  paddingRight: "70%",
                                  paddingTop: "13px",
                                  // paddingBottom: "0",
                                  height: "50px",
                                }
                              : {
                                  paddingLeft: -20,
                                }
                          }
                        />
                      </CCol>
                    </CFormGroup> */}

                    <CFormGroup className="mt-3" row>
                      <CCol lg="12" md="12">
                        <SimpleButton
                          type="submit"
                          title="Submit"
                          className="float-right btn-info px-4 mb-4 mt-2"
                        />
                      </CCol>
                    </CFormGroup>
                  </CCardBody>
                )}
              </Form>
            );
          }}
        </Formik>
      </CCard>
    </>
  );
}

export default AddRemark;
