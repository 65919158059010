import React, { useState, useEffect } from "react";
import CustomTable from "src/components/tables/index";
import { CCard, CCardBody, CFormGroup, CCol } from "@coreui/react";
import Select from "src/components/formFields/select";
import MainHeading from "src/components/MainHeading";
import useMutation from "src/hooks/useMutation";
import apiUrls from "src/apis/apis";
import SimpleButton from "src/components/buttons/simpleButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import CustomModal from "src/components/modal";
import { Form, Formik } from "formik";
import SimpleInput from "src/components/formFields/simpleInput";
import { remarksValidation } from "../add/validations";
const RemarksList = ({ leadId, refreshRemarks }) => {
  const [remarkDetails, setRemarkDetails] = useState({});
  const { getData: getRemarks } = useMutation();
  const { getData: addRemarks } = useMutation();
  const [data, setData] = useState([]);
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const { remark = "", date = "", status = "Created" } = remarkDetails || {};

  useEffect(() => {
    getRemarks({
      url: apiUrls.remarks.get,
      postData: { query: { lead_id: leadId } },
      onSuccess: (remarksData) => {
        const filteredData = remarksData.filter((item) => !item.is_deleted);
        setData(filteredData);
      },
    });
  }, [refreshRemarks]);

  return (
    <>
      {leadId && (
        <CCol xs="12" sm="12" lg="12">
          <CCard accentColor="primary">
            <MainHeading heading="Remarks" />
            <CustomTable
              columns={[
                {
                  Header: "Status",
                  accessor: "status",
                  minWidth: 150,
                },
                { Header: "Remarks", accessor: "remark", minWidth: 400 },
                {
                  Header: "Date",
                  accessor: "createdAt",
                  Cell: (props) => {
                    return (
                      <div style={{ width: "120px" }}>
                        {moment(props?.row?.values?.createdAt).format(
                          "DD/MM/YYYY hh:mm A"
                        )}
                      </div>
                    );
                  },
                },
                {
                  Header: "_id",
                  accessor: "_id",
                  show: false,
                },
                {
                  Header: "Action",
                  accessor: "",
                  Cell: (props) => {
                    console.log(props.row.values, "values");
                    return (
                      <div
                        style={{ width: "120px" }}
                        onClick={() => {
                          addRemarks({
                            url: apiUrls.remarks.add,
                            postData: {
                              data: {
                                ...props?.row.values,
                                is_deleted: true,
                              },
                            },
                            onSuccess: () => {
                              toast.success(`Remark Deleted successfully`);
                              getRemarks({
                                url: apiUrls.remarks.get,
                                postData: { query: { lead_id: leadId } },
                                onSuccess: (remarksData) => {
                                  const filteredData = remarksData.filter(
                                    (item) => !item.is_deleted
                                  );
                                  setData(filteredData);
                                },
                              });
                            },
                          });
                        }}
                      >
                        <FaTrash color="red" />
                      </div>
                    );
                  },
                },
              ]}
              data={[...data]}
            />
          </CCard>
        </CCol>
      )}
      <CustomModal
        setIsModalVisible={setIsRemarksModalOpen}
        // secondaryCtaFunction={() => {
        //   setNotBookedModalVisible(false);
        // }}
        title={"Add Remark"}
        isModalVisible={isRemarksModalOpen}
        // secondaryText="Ok"
        widthStyle={{ width: "50%" }}
        innerText={
          <div>
            <CCard>
              <Formik
                enableReinitialize
                initialValues={{
                  date,
                  remark,
                  status: data?.[0]?.status || "",
                }}
                validationSchema={remarksValidation}
                onSubmit={(values) => {
                  addRemarks({
                    url: apiUrls.remarks.add,
                    postData: {
                      data: {
                        lead_id: leadId,
                        ...values,
                      },
                    },
                    onSuccess: () => {
                      toast.success(`Remark added successfully`);
                      getRemarks({
                        url: apiUrls.remarks.get,
                        postData: { query: { lead_id: leadId } },

                        onSuccess: (remarksData) => {
                          // Filter out elements with is_deleted: true
                          console.log(remarksData, "data is logged here bro");
                          const filteredData = remarksData.filter(
                            (item) => !item.is_deleted
                          );
                          setData(filteredData);
                          setIsRemarksModalOpen(false);
                        },
                      });
                    },
                  });
                }}
              >
                {({ errors, touched, values, setFieldValue }) => {
                  return (
                    <Form>
                      {leadId && (
                        <CCardBody>
                          <CFormGroup row className="mt-3">
                            <CCol lg="6" md="6">
                              <Select
                                title="Status"
                                onChange={(e) => {
                                  setFieldValue("status", e.target.value);
                                }}
                                value={values?.status}
                                error={touched?.status && errors?.status}
                                required
                                options={[
                                  { key: "Please select", value: "" },
                                  { key: "Created", value: "Created" },
                                  {
                                    key: "Not Contactable",
                                    value: "Not Contactable",
                                  },
                                  {
                                    key: "Contact Later",
                                    value: "Contact Later",
                                  },
                                  {
                                    key: "Demo Scheduled",
                                    value: "Demo Scheduled",
                                  },
                                  { key: "Demo Given", value: "Demo Given" },
                                  {
                                    key: "On Hold",
                                    value: "On Hold",
                                  },
                                  {
                                    key: "Offer Given",
                                    key: "Offer Given",
                                  },
                                  {
                                    key: "Dropped",
                                    key: "Dropped",
                                  },
                                  {
                                    key: "Payment Received",
                                    key: "Payment Received",
                                  },
                                ]}
                              />
                            </CCol>

                            <CCol lg="6" md="6">
                              <SimpleInput
                                title="Remark"
                                placeholder="Enter Remark"
                                onChange={(e) => {
                                  setFieldValue("remark", e.target.value);
                                }}
                                date
                                value={values?.remark}
                                error={touched?.remark && errors?.remark}
                                required
                              />
                            </CCol>
                          </CFormGroup>

                          <CFormGroup className="mt-3" row>
                            <CCol lg="12" md="12">
                              <SimpleButton
                                type="submit"
                                title="Submit"
                                className="float-right btn-info px-4 mb-4 mt-2"
                              />
                            </CCol>
                          </CFormGroup>
                        </CCardBody>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </CCard>
          </div>
        }
      />
    </>
  );
};

export default RemarksList;
