const { default: apiClient } = require("src/apis/api-client");
const { default: apiUrls } = require("src/apis/apis");

const getUser = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.getUsers,
      dataToSend
    );
    console.log(data, "createUser-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "createUser-error");
    failCallback();
  }
};

const getUserV2 = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.getUsersV2,
      dataToSend
    );
    console.log(data, "createUser-getUserV2");
    successCallback(data);
  } catch (err) {
    console.log(err, "createUser-getUserV2");
    failCallback();
  }
};
const getClients = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.getClients,
      dataToSend
    );
    console.log(data, "getClients");
    successCallback(data);
  } catch (err) {
    console.log(err, "getClients");
    failCallback();
  }
};
const getCompanies = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(apiUrls.company.get, dataToSend);
    console.log(data, "getCompanies");
    successCallback(data);
  } catch (err) {
    console.log(err, "getCompanies");
    failCallback();
  }
};
const getTherapists = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.getTherapists,
      dataToSend
    );
    console.log(data, "getTherapists");
    successCallback(data);
  } catch (err) {
    console.log(err, "getTherapists");
    failCallback();
  }
};
const getPartners = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.getPartners,
      dataToSend
    );
    console.log(data, "getPartners");
    successCallback(data);
  } catch (err) {
    console.log(err, "getPartners");
    failCallback();
  }
};
const getUserPassword = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.getUserPassword,
      dataToSend
    );
    console.log(data, "response_userPassword");
    successCallback(data);
  } catch (e) {
    console.log(e, "error-userPassword");
    failCallback(e);
  }
};

const AddUser = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  console.log(dataToSend, "dataSend");
  try {
    const { data = {} } = await apiClient.post(apiUrls.user.add, dataToSend);
    console.log(data, "AddingUser-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "AddingUser-error");
    failCallback();
  }
};

const addClient = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.user.addClient,
      dataToSend
    );
    console.log(data, "addClient-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "addClient-error");
    failCallback();
  }
};
const addTherapist = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.user.addTherapist,
      dataToSend
    );
    console.log(data, "addTherapist-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "addTherapist-error");
    failCallback();
  }
};
const addPartner = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  console.log("data to send", dataToSend);
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.user.addPartner,
      dataToSend
    );
    console.log(data, "addPartner-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "addPartner-error");
    failCallback();
  }
};
const addSale = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  console.log("data to send", dataToSend);
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.user.addSale,
      dataToSend
    );
    console.log(data, "addSale-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "addSale-error");
    failCallback();
  }
};
const addOperation = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  console.log("data to send", dataToSend);
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.user.addOperation,
      dataToSend
    );
    console.log(data, "addOperation-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "addOperation-error");
    failCallback();
  }
};
const getUserGoal = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.userGoal.getUserGoals,
      dataToSend
    );
    console.log(data, "response_userGoal");
    successCallback(data);
  } catch (e) {
    console.log(e, "error-userGoal");
    failCallback(e);
  }
};

const AddUserGoal = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  console.log(dataToSend, "dataSend");
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.userGoal.addUserGoals,
      dataToSend
    );
    console.log(data, "AddingUserGoal-success");
    successCallback(data);
    return data;
  } catch (err) {
    console.log(err, "AddingUserGoal-error");
    failCallback();
  }
};

const uploadFile = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    let formData = new FormData();
    var { _id, uploadPath, file } = dataToSend;
    if (_id) {
      formData.append("_id", _id);
    }
    if (uploadPath) {
      formData.append("uploadPath", uploadPath);
    }
    if (file) {
      formData.append("file", file);
    }
    const { data = {} } = await apiClient.post(
      apiUrls.files.uploadFile,
      formData
    );
    console.log(data, "uploadFile-success");
    successCallback(data);
    return data;
  } catch (err) {
    console.log(err, "uploadFile-error");
    failCallback();
    return err;
  }
};

const getSubscriptions = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  // console.log(dataToSend, "dataSend");
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.subscriptions.get,
      dataToSend
    );
    console.log(data, "getSubscriptions-success");
    successCallback(data);
    return data;
  } catch (err) {
    console.log(err, "getSubscriptions-error");
    failCallback();
  }
};

const resetPassword = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  console.log(dataToSend, "dataSend");
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.forgotPassword,
      dataToSend
    );
    console.log(data, "getforgotPassword-success");
    successCallback(data);
    return data;
  } catch (err) {
    console.log(err, "getforgotPassword-error");
    failCallback();
  }
};

const getSales = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.getSales,
      dataToSend
    );
    console.log(data, "getSales");
    successCallback(data);
  } catch (err) {
    console.log(err, "getSales");
    failCallback();
  }
};

const getOperations = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.getOperations,
      dataToSend
    );
    console.log(data, "getOperations");
    successCallback(data);
  } catch (err) {
    console.log(err, "getOperations");
    failCallback();
  }
};

export {
  getUser,
  getUserPassword,
  AddUser,
  getUserGoal,
  AddUserGoal,
  uploadFile,
  getSubscriptions,
  resetPassword,
  getUserV2,
  getClients,
  getTherapists,
  addClient,
  addTherapist,
  addPartner,
  getPartners,
  addSale,
  getSales,
  addOperation,
  getOperations,
  getCompanies,
};
