import React from "react";

const BookSession = React.lazy(() => import("src/pages/session"));
const MovingCar = React.lazy(() => import("src/pages/games/movingCar"));
const ImageQuiz = React.lazy(() => import("src/pages/games/imageQuiz"));
const DragDrop = React.lazy(() => import("src/pages/games/dragDrop"));
const FlashCard = React.lazy(() => import("src/pages/games/flashCards"));
const Jumble = React.lazy(() => import("src/pages/games/jumble"));
const Matching = React.lazy(() => import("src/pages/games/matching"));
const demoScreen = React.lazy(() => import("src/pages/DemoGame/DemoScreen"));
const ScreeningTest = React.lazy(() => import("src/pages/screeningTest"));
const LeadAdd = React.lazy(() => import("src/pages/leads/add/index"));
const qrScreen = React.lazy(() => import("../pages/qrcode/index"));
// const leadFromWebsite = React.lazy(() => import("src/pages/leadFormForWebsite"));
const leadFromWebsite = React.lazy(() =>
  import("src/pages/leadFormForWebsite/add/index")
);
const leadFromExternal = React.lazy(() =>
  import("src/pages/leadFormForAllPurpose/add/index")
);

const sessionRoutes = [
  { path: "/session/book", name: "Book session", component: BookSession },
  {
    path: "/demogoals",
    name: "Demo Screen",
    component: demoScreen,
    exact: true,
  },
  { path: "/games/movingCar/:id?", name: "Moving Car", component: MovingCar },
  { path: "/games/imageQuiz/:id?", name: "Image Quiz", component: ImageQuiz },
  { path: "/games/dragDrop/:id?", name: "Drag Drop", component: DragDrop },
  { path: "/games/jumble/:id?", name: "Sequencing", component: Jumble },
  { path: "/games/matching/:id?", name: "Matching", component: Matching },
  { path: "/games/flashcards/:id?", name: "Flashcards", component: FlashCard },
  { path: "/screening", name: "Screening Test", component: ScreeningTest },
  { path: "/qr/code/:partner_code?", name: "QR Code", component: qrScreen },
  { path: "/qr/lead/add", name: "Add Lead", component: LeadAdd },

  {
    path: "/lead/from-website",
    name: "Add leadForm-Website",
    component: leadFromWebsite,
  },
  {
    path: "/lead/external",
    name: "Add lead",
    component: leadFromExternal,
  },
];

export default sessionRoutes;
