const { default: apiClient } = require("src/apis/api-client");
const { default: apiUrls } = require("src/apis/apis");

const changePassword = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.changePassword,
      dataToSend
    );
    console.log(data, "changePassword-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "changePassword-error");
    failCallback();
  }
};
const resetPassword = async (
  dataToSend,
  successCallback = () => {},
  failCallback = () => {}
) => {
  try {
    const { data = {} } = await apiClient.post(
      apiUrls.users.resetPassword,
      dataToSend
    );
    console.log(data, "resetPassword-success");
    successCallback(data);
  } catch (err) {
    console.log(err, "resetPassword-error");
    failCallback();
  }
};

export { changePassword, resetPassword };
