import React from "react";
import { CInput, CLabel } from "@coreui/react";
import moment from "moment";

const DatePicker = ({
  type = "date",
  id = "",
  title = "",
  placeholder = "",
  value = "",
  error,
  required,
  onChange = () => {},
  maxDate = "",
  disabled = false,
  format = "YYYY-MM-DD",
  existingFormat = "",
  isEditable = true,
  isDob = false,
  min,
  style,
}) => {
  const minDateProps = isDob
    ? { max: moment().subtract({ years: 18 }).format("YYYY-MM-DD").toString() }
    : {};

  return (
    <>
      <CLabel>
        {title}

        {required ? <span style={{ color: "red" }}> *</span> : null}
      </CLabel>
      {isEditable ? (
        <CInput
          type={type}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          value={moment(value, existingFormat).format(format)}
          initialView="year"
          max={maxDate}
          disabled={disabled}
          style={{
            borderColor: error ? "#e55353" : "",
            paddingBlock: 25,
            boxShadow: "none",
            ...style,
          }}
          min={min}
          {...minDateProps}
        />
      ) : (
        <div style={{ fontSize: 16.5, fontWeight: 500 }}>{value || "-"}</div>
      )}
      {error ? (
        <CLabel htmlFor="company" style={{ color: "#e55353", marginBottom: 0 }}>
          {error}
        </CLabel>
      ) : null}
    </>
  );
};

export default DatePicker;
