import _ from 'lodash';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import useQuery from 'src/customHooks/useQuery';
import qs from 'query-string';

const TablePagination = ({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageSize,
  setPageSize,
  pageCount,
  pageOptions,
  queryPagination,
}) => {
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const queryParams = qs.parse(location.search);

  useEffect(() => {
    if (pageIndex + 1 > pageOptions?.length) {
      gotoPage(0);
    }
  }, []);
  return (
    <div
      className="pagination flex-row justify-content-between align-items-center d-flex"
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-end">
          <li
            className={`page-item ${!canPreviousPage ? 'disabled' : ''}`}
            onClick={() => {
              if (canPreviousPage) {
                if (queryPagination) {
                  const newQueries = {
                    ...queryParams,
                    page: parseInt(query?.get('page')) - 1,
                  };

                  history.replace({ search: qs.stringify(newQueries) });
                } else {
                  previousPage();
                }
              }
            }}
            disabled={!canPreviousPage}
          >
            <a class="page-link" href="javascript:void(0)" tabindex="-1">
              Previous
            </a>
          </li>

          <li>
            <ul className="d-flex flex-wrap list-unstyled">
              {_.times(pageOptions.length, (index) => {
                return (
                  <li
                    style={{ width: '50px' }}
                    className={`page-item  ${
                      pageIndex === index ? 'disabled pagination-item' : ''
                    }`}
                    onClick={() => {
                      if (queryPagination) {
                        const newQueries = {
                          ...queryParams,
                          page: index + 1,
                        };

                        history.replace({ search: qs.stringify(newQueries) });
                      } else {
                        gotoPage(index);
                      }
                    }}
                  >
                    <a
                      class={`page-link ${
                        pageIndex === index ? 'pagination-item' : ''
                      }`}
                      href="javascript:void(0)"
                    >
                      {index + 1}
                    </a>
                  </li>
                );
              })}
            </ul>
          </li>

          <li
            className={`page-item ${!canNextPage ? 'disabled' : ''}`}
            onClick={() => {
              if (canNextPage) {
                if (queryPagination) {
                  const newQueries = {
                    ...queryParams,
                    page: query?.get('page')
                      ? parseInt(query?.get('page')) + 1
                      : 2,
                  };

                  history.replace({ search: qs.stringify(newQueries) });
                } else {
                  nextPage();
                }
              }
            }}
          >
            <a class="page-link" href="javascript:void(0)">
              Next
            </a>
          </li>
        </ul>
      </nav>

      {/* <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
        class="form-select"
        aria-label="Default select example"
      >
        {[5, 10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select> */}
    </div>
  );
};

export default TablePagination;
