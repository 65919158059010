import moment from "moment";
import { store } from "src/redux/store";

const _nav = (role, _id) => {
  const hidden = role !== "super_admin" && _id !== "665a932fcf5ae6ce8797c7c5";
  return [
    {
      _tag: "CSidebarNavItem",
      name: "Dashboard",
      className: "font-weight-bold ",
      to: "/dashboard",
      icon: "cil-gauge",
      showTo: ["super_admin", "partner", "therapist", "operation", "sales"],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "User Management",
      className: "font-weight-bold",

      icon: "cil-user-follow",
      showTo: ["super_admin"],
      _children: [
        {
          _tag: "CSidebarNavDropdown",
          name: "Companies",
          className: "font-weight-bold",

          icon: "cil-building",
          showTo: ["super_admin"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/companies/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/companies/add",
            },
          ],
        },
        {
          _tag: "CSidebarNavDropdown",
          name: "Therapists",
          className: "font-weight-bold",

          icon: "cil-user-follow",
          showTo: ["super_admin", "company", "operation"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/users/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/users/add",
            },
          ],
        },

        {
          _tag: "CSidebarNavDropdown",
          name: "Partners",
          className: "font-weight-bold",

          icon: "cil-user-follow",
          showTo: ["sales"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/partners/list",
            },
          ],
        },

        {
          _tag: "CSidebarNavDropdown",
          name: "Sales Team",
          className: "font-weight-bold",

          icon: "cil-user-follow",
          showTo: ["super_admin", "company"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/sales/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/sales/add",
            },
          ],
        },
        {
          _tag: "CSidebarNavDropdown",
          name: "Operations",
          className: "font-weight-bold",

          icon: "cil-user-follow",
          showTo: ["super_admin", "company"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/operation/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/operation/add",
            },
          ],
        },

        {
          _tag: "CSidebarNavDropdown",
          name: "Clients",
          className: "font-weight-bold",

          icon: "cil-user",
          showTo: ["super_admin", "company", "operation"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/clients/list",
            },

            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/clients/add",
            },
          ],
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "User Management",
      className: "font-weight-bold",

      icon: "cil-user-follow",
      showTo: ["company", "operation"],
      _children: [
        {
          _tag: "CSidebarNavDropdown",
          name: "Therapists",
          className: "font-weight-bold",

          icon: "cil-user-follow",
          showTo: ["super_admin", "company", "operation"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/users/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/users/add",
            },
          ],
        },
        {
          _tag: "CSidebarNavDropdown",
          name: "Partners",
          className: "font-weight-bold",

          icon: "cil-user-follow",
          showTo: ["super_admin", "company", "operation"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/partners/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/partners/add",
            },
          ],
        },

        {
          _tag: "CSidebarNavDropdown",
          name: "Sales Team",
          className: "font-weight-bold",

          icon: "cil-user-follow",
          showTo: ["super_admin", "company"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/sales/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/sales/add",
            },
          ],
        },
        {
          _tag: "CSidebarNavDropdown",
          name: "Operations",
          className: "font-weight-bold",

          icon: "cil-user-follow",
          showTo: ["super_admin", "company"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/operation/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/operation/add",
            },
          ],
        },

        {
          _tag: "CSidebarNavDropdown",
          name: "Clients",
          className: "font-weight-bold",

          icon: "cil-user",
          showTo: ["super_admin", "company", "operation"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/clients/list",
            },

            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/clients/add",
            },
          ],
        },
      ],
    },

    {
      _tag: "CSidebarNavDropdown",
      name: "Partners",
      className: "font-weight-bold",

      icon: "cil-user-follow",
      showTo: ["sales"],
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "List",
          to: "/partners/list",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Add",
          to: "/partners/add",
        },
      ],
    },

    {
      _tag: "CSidebarNavDropdown",
      name: "Catalogue & Pricing",
      className: "font-weight-bold",

      icon: "cil-list",
      showTo: ["super_admin", "company"],
      _children: [
        {
          _tag: "CSidebarNavDropdown",
          name: "Plans",
          className: "font-weight-bold",
          icon: "cil-list",
          showTo: ["super_admin", "company"],

          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/plans/list",
            },

            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/plans/add",
            },
          ],
        },
        {
          _tag: "CSidebarNavDropdown",
          name: "Discount",
          className: "font-weight-bold",

          icon: "cil-list",
          showTo: ["super_admin", "company"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/discountCoupons/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/discountCoupons/add",
            },
          ],
        },
      ],
    },

    {
      _tag: "CSidebarNavDropdown",
      name: "Capacity Management",
      className: "font-weight-bold",

      icon: "cil-calendar",
      showTo: ["super_admin", "company", "operation"],
      _children: [
        {
          _tag: "CSidebarNavDropdown",
          name: "Slots",
          className: "font-weight-bold",

          icon: "cil-calendar",
          showTo: ["super_admin", "company", "operation"],

          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: `/Slots/list?startdate=${moment().format(
                "YYYY-MM-DD"
              )}&enddate=${moment().format("YYYY-MM-DD")}&first_load=true`,
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/Slots/Add",
            },
          ],
        },
        {
          _tag: "CSidebarNavItem",
          name: "Assessments",
          className: "font-weight-bold",
          to: `/allAppointments?startdate=${moment().format(
            "YYYY-MM-DD"
          )}&enddate=${moment().format("YYYY-MM-DD")}`,
          icon: "cil-book",
          showTo: ["super_admin", "company", "operation"],
        },
        {
          _tag: "CSidebarNavItem",
          name: "Calendar View",
          to: "/calendar-view",
          icon: "cil-calendar",
        },
      ],
    },

    {
      _tag: "CSidebarNavDropdown",
      name: "Billing & Payment",
      className: "font-weight-bold",

      icon: "cil-money",
      showTo: ["super_admin", "company", "operation"],
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Transactions",
          className: "font-weight-bold",
          to: `/transactions`,
          icon: "cil-money",
          showTo: ["super_admin", "company", "operation"],
          hidden,
        },

        {
          _tag: "CSidebarNavDropdown",
          name: "Payments Link",
          className: "font-weight-bold",
          icon: "cil-money",
          showTo: ["super_admin", "company", "operation"],
          hidden,

          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/payments/links",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Create",
              to: "/payments/createLink",
            },
          ],
        },

        {
          _tag: "CSidebarNavItem",
          name: "Invoices",
          className: "font-weight-bold",
          icon: "cil-money",
          showTo: ["super_admin", "company", "operation"],
          to: "/invoices/list",
        },

        {
          _tag: "CSidebarNavItem",
          name: "Payment Approval",
          className: "font-weight-bold",
          icon: "cil-money",
          showTo: ["super_admin", "company", "operation"],
          to: "/bankTransfer",
        },
      ],
    },

    {
      _tag: "CSidebarNavDropdown",
      name: "Curriculum",
      className: "font-weight-bold",

      icon: "cil-puzzle",
      showTo: ["therapist", "super_admin", "company"],
      _children: [
        {
          _tag: "CSidebarNavDropdown",
          name: "Master Activity",
          className: "font-weight-bold",
          icon: "cil-puzzle",
          showTo: ["therapist", "super_admin", "company"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/masterActivity/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/masterActivity/add",
            },
          ],
        },
        {
          _tag: "CSidebarNavDropdown",
          name: "Master Questions",
          className: "font-weight-bold",
          icon: "cil-puzzle",
          showTo: ["therapist", "super_admin", "company"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/masterQuestions/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/masterQuestions/add",
            },
          ],
        },

        {
          _tag: "CSidebarNavDropdown",
          name: "Master Goals",
          className: "font-weight-bold",
          icon: "cil-football",
          showTo: ["therapist", "super_admin", "company"],
          _children: [
            {
              _tag: "CSidebarNavItem",
              name: "List",
              to: "/masterGoal/list",
            },
            {
              _tag: "CSidebarNavItem",
              name: "Add",
              to: "/masterGoal/add",
            },
          ],
        },
        {
          _tag: "CSidebarNavItem",
          name: "Tutorials",
          className: "font-weight-bold",
          icon: "cil-video",
          showTo: ["therapist", "super_admin", "company"],
          to: "/tutorials",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Master Videos",
          className: "font-weight-bold",
          icon: "cil-video",
          showTo: ["therapist", "super_admin", "company"],
          to: "/masterVideo",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Media Manager",
          className: "font-weight-bold",
          to: "/media-manager/list",
          icon: "cil-spreadsheet",
          showTo: ["super_admin", "company"],
        },
      ],
    },

    {
      _tag: "CSidebarNavDropdown",
      name: "Leads",
      className: "font-weight-bold",

      icon: "cil-user",
      showTo: ["super_admin", "company", "partner", "sales", "operation"],
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "List",
          to: "/leads/list",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Add",
          to: "/leads/add",
        },
      ],
    },

    {
      _tag: "CSidebarNavItem",
      name: "My Clients",
      className: "font-weight-bold",
      to: `/my/clients/list`,
      icon: "cil-user",
      showTo: ["therapist"],
    },

    // {
    //   _tag: "CSidebarNavDropdown",
    //   name: "My Clients",
    //   className: "font-weight-bold ml-2 mr-2",

    //   icon: "cil-user",
    //   showTo: ["therapist"],
    //   _children: [
    //     {
    //       _tag: "CSidebarNavItem",
    //       name: "List",
    //       to: `/my/clients/list`,
    //     },

    //     {
    //       _tag: "CSidebarNavItem",
    //       name: "Add",
    //       to: `/my/clients/add`,
    //     },
    //   ],
    // },

    {
      _tag: "CSidebarNavItem",
      name: "Calendar View",
      className: "font-weight-bold",
      icon: "cil-calendar",
      showTo: ["sales"],
      to: "/calendar-view",
    },

    {
      _tag: "CSidebarNavItem",
      name: "View Plans",
      className: "font-weight-bold",
      to: "/plan/view",
      icon: "cil-user",
      showTo: ["sales", "operation"],
    },
    {
      _tag: "CSidebarNavItem",
      name: "Discount",
      className: "font-weight-bold",
      icon: "cil-money",
      showTo: ["sales", "operation"],
      to: "/discountCoupons/list",
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "My Slots",
      className: "font-weight-bold",
      icon: "cil-calendar",
      showTo: ["therapist"],
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "List",
          to: `/my/slots/list?startdate=${moment().format(
            "YYYY-MM-DD"
          )}&enddate=${moment().format("YYYY-MM-DD")}&first_load=true`,
        },
        {
          _tag: "CSidebarNavItem",
          name: "Calendar View",
          to: "/calendar-view",
        },
      ],
    },

    {
      _tag: "CSidebarNavItem",
      name: "Assessments",
      className: "font-weight-bold",
      to: `/my/appointments/list?startdate=${moment().format(
        "YYYY-MM-DD"
      )}&enddate=${moment().format("YYYY-MM-DD")}`,
      icon: "cil-book",
      showTo: ["therapist"],
    },

    // {
    //   _tag: "CSidebarNavItem",
    //   name: "Games",
    //   className: "font-weight-bold ml-2 mr-2",
    //   to: "/games/movingCar",
    //   icon: "cil-gamepad",
    //   showTo: ["client"],
    // },
    // {
    //   _tag: "CSidebarNavItem",
    //   name: "Master Games",
    //   className: "font-weight-bold",
    //   to: "/master-games",
    //   icon: "cil-gamepad",
    //   showTo: ["therapist"],
    // },
    {
      _tag: "CSidebarNavItem",
      name: "My Goals",
      className: "font-weight-bold",
      to: `/my_goals/${store?.getState()?.auth?.userDetails?._id}`,
      icon: "cil-gamepad",
      showTo: ["client"],
    },

    // {
    //   _tag: "CSidebarNavDropdown",
    //   name: "Add Tutorial",
    //   className: "font-weight-bold ml-2",
    //   icon: "cil-video",
    //   showTo: ["therapist", "super_admin", "company"],
    //   _children: [
    //     {
    //       _tag: "CSidebarNavItem",
    //       name: "Add",
    //       to: "/tutorials/add",
    //     },
    //     {
    //       _tag: "CSidebarNavItem",
    //       name: "List",
    //       to: "/tutorials/list",
    //     },
    //   ],
    // },

    {
      _tag: "CSidebarNavDropdown",
      name: "Reports",
      className: "font-weight-bold",

      icon: "cil-list",
      showTo: ["super_admin", "company"],
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "Zoom Report",
          to: "/zoom-report",
          icon: "cib-read-the-docs",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Screening Tests",
          className: "font-weight-bold",
          icon: "cil-video",
          showTo: ["super_admin", "company"],
          to: "/screening-tests",
          hidden,
        },
        {
          _tag: "CSidebarNavItem",
          name: "Download",
          className: "font-weight-bold",
          icon: "cil-cloud-download",
          showTo: ["super_admin", "company"],
          to: "/allAppointmentDetails",
        },
      ],
    },

    {
      _tag: "CSidebarNavItem",
      name: "Demo - Goals",
      className: "font-weight-bold",
      icon: "cil-video",
      showTo: ["super_admin", "company"],
      to: "/demogoals/add",
      hidden,
    },
    {
      _tag: "CSidebarNavItem",
      name: "Demo Goals Requests",
      className: "font-weight-bold",
      to: `/demoGoalsRequests`,
      icon: "cil-list",
      showTo: ["super_admin", "company"],
      hidden,
    },
    {
      _tag: "CSidebarNavItem",
      name: "Logs",
      className: "font-weight-bold",
      to: `/logs/list`,
      icon: "cil-list",
      showTo: ["super_admin"],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: "Client Documents",
      className: "font-weight-bold",

      icon: "cib-read-the-docs",
      showTo: ["super_admin", "company", "therapist"],
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: "List",
          to: "/client-documents/list",
        },
        {
          _tag: "CSidebarNavItem",
          name: "Add",
          to: "/client-documents/add",
        },
      ],
    },
  ];
};

export default _nav;

/* {


  {
    _tag: "CSidebarNavDropdown",
    name: "Therapist",
    className: "font-weight-bold ml-2 mr-2",

    icon: <i class="mr-4 fas fa-clinic-medical"></i>,

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Add",
        to: "/Therapist/Add",
      },
      {
        _tag: "CSidebarNavItem",
        name: "List",
        to: "/Therapist/List",
      },
    ],
  },


  {

  /* {

    _tag: "CSidebarNavTitle",
    _children: ["Theme"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Colors",
    to: "/theme/colors",
    className: "font-weight-bold ml-2 mr-2",
    icon: <FontAwesomeIcon className="mr-4" icon={["fas", "tint"]} />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Typography",
    className: "font-weight-bold ml-2 mr-2",
    to: "/theme/typography",
    icon: <FontAwesomeIcon className="mr-4" icon={["fas", "pen"]} />,
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Components"],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Base",
    className: "font-weight-bold ml-2 mr-2",

    route: "/base",
    icon: <FontAwesomeIcon className="mr-4" icon={["fab", "sketch"]} />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Breadcrumb",
        to: "/base/breadcrumbs",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Cards",
        to: "/base/cards",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Carousel",
        to: "/base/carousels",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Collapse",
        to: "/base/collapses",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Forms",
        to: "/base/forms",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Jumbotron",
        to: "/base/jumbotrons",
      },
      {
        _tag: "CSidebarNavItem",
        name: "List group",
        to: "/base/list-groups",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Navs",
        to: "/base/navs",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Navbars",
        to: "/base/navbars",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Pagination",
        to: "/base/paginations",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Popovers",
        to: "/base/popovers",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Progress",
        to: "/base/progress-bar",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Switches",
        to: "/base/switches",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Tables",
        to: "/base/tables",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Tabs",
        to: "/base/tabs",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Tooltips",
        to: "/base/tooltips",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Buttons",
    route: "/buttons",
    className: "font-weight-bold ml-2 mr-2",

    icon: <FontAwesomeIcon className="mr-4" icon={["fas", "database"]} />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Buttons",
        to: "/buttons/buttons",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Brand buttons",
        to: "/buttons/brand-buttons",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Buttons groups",
        to: "/buttons/button-groups",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Dropdowns",
        to: "/buttons/button-dropdowns",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Charts",
    className: "font-weight-bold ml-2 mr-2",
    to: "/charts",
    icon: <FontAwesomeIcon className="mr-4" icon={["fas", "chart-pie"]} />,
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Icons",
    className: "font-weight-bold ml-2 mr-2",

    route: "/icons",
    icon: <FontAwesomeIcon className="mr-4" icon={["fas", "icons"]} />,
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "CoreUI Free",
        to: "/icons/coreui-icons",
        badge: {
          color: "success",
          text: "NEW",
        },
      },
      {
        _tag: "CSidebarNavItem",
        name: "CoreUI Flags",
        to: "/icons/flags",
      },
      {
        _tag: "CSidebarNavItem",
        name: "CoreUI Brands",
        to: "/icons/brands",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Notifications",
    route: "/notifications",
    className: "font-weight-bold ml-2 mr-2",
    icon: <FontAwesomeIcon className="mr-4" icon={["fas", "bell"]} />,

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Alerts",
        to: "/notifications/alerts",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Badges",
        to: "/notifications/badges",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Modal",
        to: "/notifications/modals",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Toaster",
        to: "/notifications/toaster",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Widgets",
    className: "font-weight-bold ml-2 mr-2",
    to: "/widgets",
    icon: <FontAwesomeIcon className="mr-4" icon={["fas", "list"]} />,

    badge: {
      color: "info",
      text: "NEW",
    },
  },
  {
    _tag: "CSidebarNavDivider",
  },

  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Disabled',
  //   icon: 'cil-ban',
  //   badge: {
  //     color: 'secondary',
  //     text: 'NEW',
  //   },
  //   addLinkClass: 'c-disabled',
  //   'disabled': true
  // },
  // {
  //   _tag: 'CSidebarNavDivider',
  //   className: 'm-2'
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Labels']
  // },
  //   {
  //     _tag: 'CSidebarNavItem',
  //     name: 'Label danger',
  //     to: '',
  //     icon: {
  //       name: 'cil-star',
  //       className: 'text-danger'
  //     },
  //     label: true
  //   },*/
