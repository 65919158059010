import moment from "moment";
import { store } from "src/redux/store";
import { resetPassword } from "src/pages/users/api";
import { toast } from "react-toastify";
import sessionRoutes from "src/routes/sessionRoutes";
const logout = () => () => {
  localStorage.clear();
  window.location.href = "/login";
};

export const isSubscribed = (plan) => {
  let plan_id = store?.getState()?.auth?.userDetails?.subscription_id;
  let valid_till = store?.getState()?.auth?.userDetails?.valid_till;
  const isSessionRoute = sessionRoutes.find((item) =>
    item?.path?.includes(window.location.pathname)
  );

  if (store?.getState()?.auth?.userDetails?.is_password_resetted) {
    if (isSessionRoute) {
      return true;
    }
    if (plan_id && moment(valid_till) >= moment()) {
      if (plan && plan_id !== plan) {
        return false;
      } else return true;
    } else {
      return false;
    }
  } else {
    if (!window.location.pathname?.includes("/password/reset"))
      window.location.href = "/password/reset";
  }
};

const getDob = (dob) => {
  let str = moment(dob)?.startOf("day")?.fromNow();

  let subStr = str?.substring(0, str?.length - 3);

  return subStr;
};

const onClickResetPassword = (id, getData) => {
  resetPassword({ user_id: id }, (data) => {
    console.log(data, "data");
    if (data?.status === "success") {
      toast.success("Password has been resetted");
      getData();
    } else {
      toast.error("Something went wrong");
    }
  });
};

const calculateRatio = (num_1, num_2) => {
  for (let num = num_2; num > 1; num--) {
    if (num_1 % num === 0 && num_2 % num === 0) {
      num_1 = num_1 / num;
      num_2 = num_2 / num;
    }
  }
  var ratio = num_1 + ":" + num_2;
  return ratio;
};

const loadImage = (setImageDimensions, imageUrl) => {
  if (imageUrl) {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setImageDimensions({ width: img?.width, height: img?.height });
    };
  }
};

export { logout, getDob, onClickResetPassword, calculateRatio, loadImage };
