import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import apiUrls from "src/apis/apis";
import { SET_LOADER } from "src/redux/actions";
import ModalButton from "../buttons/modalButton";
import SimpleButton from "../buttons/simpleButton";
import DynamicSelectInput from "../DynamicSelectInput";
import SimpleInput from "../formFields/simpleInput";
import Select from "react-select";
import useMutation from "src/hooks/useMutation";
// import { SET_LOADER } from "../redux/actions";
// import { deleteMilestoneTasks } from "./apis";
import "./modal.css";
const CustomModal = (props) => {
  const dispatch = useDispatch();
  let { data = [], getData } = useMutation();
  const {
    setIsModalVisible,
    isModalVisible,
    secondaryCtaFunction = () => {},
    primaryCtaFunction = () => {},
    primaryText = "",
    secondaryText = "",
    innerText = "",
    title = "",
    loader = false,
    color = "",
    input = false,
    widthStyle,
    closable = true,
    renderContent = () => {},
    img = "",
    isTransparent = false,
    className = "",
    user_id,
    role,
    style,
  } = props;
  const toggle = () => setIsModalVisible(!isModalVisible);
  const [inputText, setInputText] = useState("");
  const [error, setError] = useState("");
  const urlS = apiUrls.users.getClients;
  let options = [
    ...data?.map((item, index) => ({
      label: item.name,
      value: item?._id,
    })),
  ];

  useEffect(() => {
    if (input) {
      getData({
        url: urlS,
        postData: {
          query: {
            status: "Active",
            therapist_id: role === "therapist" ? user_id : undefined,
          },
        },
      });
    }
  }, []);

  return (
    <div className={className}>
      <Modal
        isOpen={isModalVisible}
        style={widthStyle}
        toggle={toggle}
        className={`modal-content ${className} ${
          isTransparent ? "transparent-background" : ""
        }`}
      >
        <ModalHeader toggle={closable ? toggle : false}>{title}</ModalHeader>
        <ModalBody>
          <div>{innerText}</div>
          {img ? (
            <div>
              <img src={img} className="modal-img" />
            </div>
          ) : null}
          {input ? (
            /*  <DynamicSelectInput
              initialKey="Please Select"
              title="Select user to book slot"
              url={apiUrls.users.getUsers}
              postData={{ query: { role: "client" } }}
              objectKey="name"
              valueKey="_id"
              onChange={(e) => {
                setInputText(e.target.value);
              }}
              value={inputText}
            /> */
            <Select
              placeholder="Please Select"
              options={options}
              value={inputText}
              onChange={(e) => {
                setInputText(e);
              }}
              isSearchable
              objectKey="name"
              valueKey="_id"
            />
          ) : (
            renderContent()
          )}
          <div style={{ textAlign: "center", color: "red" }}>{error}</div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            className="cancel-btn"
            style={{
              background: "none",
              border: "none",
              fontWeight: "bold",
              color: "#54a3b3",
            }}
            onClick={secondaryCtaFunction}
          >
            {secondaryText}
          </Button>{" "}
          {primaryText ? (
            <div
              onClick={() => {
                if (input) {
                  if (!inputText) {
                    setError("User is required");
                    return;
                  } else {
                    primaryCtaFunction(inputText);
                  }
                } else {
                  primaryCtaFunction();
                }
              }}
            >
              <ModalButton loader={loader} title={primaryText} color={color} />
            </div>
          ) : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CustomModal;
// color={color}
// loader={loader}
