import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
import { BiPhoneCall } from "react-icons/bi";
import phonoLogo from "src/assets/icons/phonologix.png";
import phono from "src/assets/icons/ph.png";

const TheSidebar = ({ navigation }) => {
  const dispatch = useDispatch();
  const [sidebarState, setsidebarState] = useState(false);
  const show = useSelector((state) => state.commonReducer.sidebarShow);
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  console.log(userDetails, "userDetails?.logo");
  const [userId, setUserId] = useState("");
  useEffect(() => {
    setUserId(
      window.location.pathname.split("/")[
        window.location.pathname.split("/").length - 1
      ]
    );
  }, []);

  return (
    <>
      <CSidebar
        className="sidebar"
        style={{
          background:
            userDetails?.theme_color ||
            userDetails?.company_details?.theme_color ||
            "rgba(34,41,67)",
        }}
        show={show}
        onShowChange={(val) => dispatch({ type: "set", sidebarShow: false })}
      >
        <div className="text-center">
          <h4
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            {!sidebarState ? (
              <img
                src={
                  userDetails?.logo
                    ? userDetails?.logo
                    : userDetails?.company_details?.logo
                    ? userDetails?.company_details?.logo
                    : phonoLogo
                }
                style={{
                  height: "100px",
                  width: "222px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <img
                src={
                  userDetails?.mobile_logo
                    ? userDetails?.mobile_logo
                    : userDetails?.company_details?.mobile_logo
                    ? userDetails?.company_details?.mobile_logo
                    : phonoLogo
                }
                style={{ height: "70px", width: "240px", marginLeft: "20px" }}
              />
            )}
            {/* <BiPhoneCall style={{ fontSize: 25, marginRight: 23 }} />{" "}
            {!sidebarState && "Phonologix"} */}
          </h4>
        </div>

        <CSidebarNav>
          <CCreateElement
            items={navigation().filter(
              (item) =>
                !item?.hidden && item?.showTo?.includes(userDetails?.role)
            )}
            components={{
              CSidebarNavDivider,
              CSidebarNavDropdown,
              CSidebarNavItem,
              CSidebarNavTitle,
            }}
          />
        </CSidebarNav>

        <div
          style={{ width: !sidebarState ? "100%" : "initial" }}
          onClick={() => setsidebarState(!sidebarState)}
        >
          <CSidebarMinimizer className="c-d-md-down-none" />
        </div>
      </CSidebar>
    </>
  );
};

export default React.memo(TheSidebar);
