import React from "react";
import { Redirect } from "react-router";
// import { TheContent, TheSidebar, TheFooter, TheHeader } from "./index";
import localStorageConstants from "src/constants/localstorageConstants";
import navigation from "./_nav";
import adminRoutes from "src/routes/adminRoutes";
import therapistRoutes from "src/routes/therapistRoutes";
import salesRoutes from "src/routes/salesRoutes";
import {
  getClients,
  getPartners,
  getTherapists,
  getOperations,
  getSales,
  getUser,
  getCompanies,
} from "src/pages/users/api";
import { logout } from "src/utils/system";
import { useSelector, useDispatch } from "react-redux";
import clientRoutes from "src/routes/clientRoutes";
import {
  SET_SHOW_COMMON_MODAL,
  SET_SHOW_COMMON_MODAL_CONTENT,
} from "src/redux/actions";
import { useIdleTimer } from "react-idle-timer";
import { useLocation } from "react-router-dom";
import partnerRoutes from "src/routes/partnerRoutes";
import operationRoutes from "src/routes/operationRoutes";
import companyRoutes from "src/routes/companyRoutes";
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheContent from "./TheContent";
import TheFooter from "./TheFooter";

const TheLayout = () => {
  const userId = localStorage.getItem(localStorageConstants.userId);
  const { role, _id } = useSelector((state) => state?.auth?.userDetails);
  const dispatch = useDispatch();
  const location = useLocation();

  console.log(userId, "userId");

  const handleOnIdle = (event) => {
    if (_id) {
      dispatch({
        type: SET_SHOW_COMMON_MODAL,
        payload: true,
      });
      dispatch({
        type: SET_SHOW_COMMON_MODAL_CONTENT,
        payload: {
          title: "Time out",
          innerText:
            "For the safety of your information, you have been logged out. Please login again",
          primaryText: "Ok",
          primaryCtaFunction: () => {
            dispatch({
              type: SET_SHOW_COMMON_MODAL,
              payload: false,
            });
          },
        },
      });
      dispatch(logout());
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 40,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  if (!userId) {
    return <Redirect to="/login" />;
  }

  let routes =
    role === "super_admin"
      ? adminRoutes
      : role === "company"
      ? companyRoutes
      : role === "therapist"
      ? therapistRoutes
      : role === "partner"
      ? partnerRoutes
      : role === "sales"
      ? salesRoutes
      : role === "operation"
      ? operationRoutes
      : clientRoutes;

  if (role === "company") {
    getCompanies(
      {
        query: { _id: userId },
      },
      (data) => {
        if (data?.data?.[0]?.status !== "Active") {
          dispatch(logout());
        }
      }
    );
  }
  if (role === "client") {
    getClients(
      {
        query: { _id: userId },
      },
      (data) => {
        if (data?.data?.data?.[0]?.status !== "Active") {
          console.log("I am here");
          dispatch(logout());
        }
      }
    );
  }
  if (role === "therapist") {
    getTherapists(
      {
        query: { _id: userId },
      },
      (data) => {
        if (data?.data?.[0]?.status !== "Active") {
          console.log("I am here");
          dispatch(logout());
        }
      }
    );
  }
  if (role === "partner") {
    getPartners(
      {
        query: { _id: userId },
      },
      (data) => {
        if (data?.data?.[0]?.status !== "Active") {
          console.log("I am here");
          dispatch(logout());
        }
      }
    );
  }
  if (role === "sales") {
    getSales(
      {
        query: { _id: userId },
      },
      (data) => {
        if (data?.data?.[0]?.status !== "Active") {
          console.log("I am here");
          dispatch(logout());
        }
      }
    );
  }
  if (role === "operations") {
    getOperations(
      {
        query: { _id: userId },
      },
      (data) => {
        if (data?.data?.[0]?.status !== "Active") {
          console.log("I am here");
          dispatch(logout());
        }
      }
    );
  }

  if (role === "client") {
    return <Redirect to="/client/dashboard" />;
  }

  return (
    <div className="c-app c-default-layout">
      <TheSidebar navigation={() => navigation(role, _id)} />
      <div className="c-wrapper" style={{ minHeight: 0 }}>
        <TheHeader />
        <div className="c-body c-wrapper" style={{ minHeight: 0 }}>
          <TheContent routes={routes} />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default TheLayout;
