import React, { useEffect, useState } from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { logout } from "src/utils/system";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import localStorageConstants from "src/constants/localstorageConstants";
import CommonModal from "src/components/modal";

const TheHeaderDropdown = () => {
  const userId = useSelector((state) => state.auth?.userDetails?._id);
  console.log(userId, "UserUd");

  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalVisible, setisModalVisible] = useState(false);

  const { _id } = useParams();

  const user = localStorage.getItem(localStorageConstants.userId);

  // useEffect(() => {
  //   if ((_id !== "check" && !userId) || (_id === "check" && !user)) {
  //     history.push("/dashboard");
  //   }
  // }, []);

  return (
    <CDropdown
      inNav
      className="d-flex c-header-nav-items flex-row mx-2"
      direction="down"
    >
      <CommonModal
        setIsModalVisible={setisModalVisible}
        isModalVisible={isModalVisible}
        innerText="Are You sure you want to log out ?"
        primaryText="Yes"
        secondaryText="Cancel"
        primaryCtaFunction={() => dispatch(logout())}
        title="Confirmation"
        secondaryCtaFunction={() => {
          setisModalVisible(false);
        }}
      />

      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <i className="fas fa-user-circle" style={{ fontSize: 30 }}></i>
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          onClick={() => {
            history.push({
              pathname: "/password/change",
              state: {
                userId,
              },
            });
          }}
        >
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Change Password
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem
          onClick={() => {
            setisModalVisible(true);
          }}
        >
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
