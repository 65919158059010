import React from "react";

const TableGeaderComponent = ({ headerGroup, actions }) => {
  return (
    <>
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th
            className="flex-row align-items-center justify-content-between"
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            {column.render("Header")}
            <span>
              {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
            </span>
          </th>
        ))}

        {actions ? <th>Actions</th> : null}
      </tr>
      {headerGroup.headers.find((col) => col?.canFilter) ? (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <>
              <th
                {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                })}
              >
                <div>{column.canFilter ? column.render("Filter") : null}</div>
              </th>
            </>
          ))}
          <th></th>
        </tr>
      ) : null}
    </>
  );
};

export default TableGeaderComponent;
