const baseUrlWithSlash = process.env.REACT_APP_API_BASE_URL;
// const baseUrlWithSlash = "https://prod.65.1.138.47.nip.io/";
// "http://localhost:2002/";
//phonologix@gmail.com
// const baseUrlWithSlash = "http://localhost:2002/";

// process.env.REACT_APP_API_BASE_URL;
// "http://localhost:2002/";

const apiUrls = {
  sendWhatsapp: "whatsapp/send-message",
  getMessages: "whatsapp/get-messages",
  appointments: {
    add: "appointments/add",
    get: "appointments/get",
    getInvoice: "invoice/getInvoices",
    getUpcomingAppointments: "appointments/getUpcoming",
    createInvoice: "appointments/createInvoice",
    schedule: "appointments/schedule",
    scheduleCustom: "appointments/schedule-custom",
    bookForUser: "appointments/bookForUser",
    book: "appointments/book",
    getAll: "appointments/getAllforUser",
  },
  transactions: {
    get: "transactions/get",
  },
  user: {
    add: "user/add",
    addClient: "user/addClient",
    addTherapist: "user/addTherapist",
    addPartner: "user/addPartner",
    addSale: "user/addSales",
    addOperation: "user/addOperation",
    get: "users/",
  },
  company: {
    add: "user/addCompany",
    get: "user/getCompany",
  },
  lead: {
    add: "leads/add",
    get: "leads/get",
  },

  invoice: {
    add: "appointments/createInvoice",
    get: "appointments/get",
  },
  remarks: {
    add: "remarks/add",
    get: "remarks/get",
  },
  discountCoupons: {
    add: "discountCoupons/add",
    get: "discountCoupons/get",
    delete: "discountCoupons/delete",
  },

  clientRemarks: {
    add: "clientRemarks/add",
    get: "clientRemarks/get",
  },

  users: {
    getUsers: "user/",
    getUsersV2: "user/getV2",
    getClients: "user/getClients",
    getTherapists: "user/getTherapists",
    getPartners: "user/getPartners",
    getPartner: "user/getPartner",
    getOperations: "user/getOperations",
    getOperation: "user/getOperation",
    getSales: "user/getSales",
    getSale: "user/getSale",
    login: "user/login",
    resetPassword: "user/resetPassword",
    changePassword: "user/changePassword",
    verifyOtp: "user/verifyOtp",
    getUserPassword: "user/getUserPassword",
    getSuggested: "user/suggested",
    uploadProfilePicture: "user/uploadProfilePicture",
    forgotPassword: "user/forgotPassword",
  },
  userGoal: {
    addUserGoals: "userGoals/add",
    getUserGoals: "userGoals/v2/get",
    copyUserGoals: "userGoals/copy",
    getUserGoalsV3: "userGoals/v3/get",
  },
  razorpay: {
    createPaymentLink: "razorpay/createPaymentLink",
    getPaymentLinks: "razorpay/getPaymentLinks",
    createOrder: "razorpay/createOrder",
    capturePayment: "razorpay/capturePayment",
  },
  subHistory: {
    add: "subHistory/add",
    get: "subHistory/get",
    getClientSubscriptionHistory: "subHistory/getClientSubscriptionHistory",
  },
  slots: {
    add: "slot/add",
    update: "slot/update",
    get: "slot/getAll",
    getSlots: "slot/get",
    getOne: "slot/getOne",
    getSlotsByQuery: "slot/getSlotsByQuery",
    getSlotsByQueryV2: "slot/v2/getSlotsByQuery",
    rescheduleSlot: "appointments/reschedule",
    rescheduleDemo: "slot/reschedule/demo",
    getSlotsForFirstAppointment: "slot/getSlotsForFirstAppointment",
    suggestedDate: `slot/suggestion`,
    getClientSlots: `slot/getClientSlots`,
    getTherapistsForSlotChange: `slot/getTherapistsForSlotChange`,
    changeTherapistBooking: `slot/changeTherapistInBooking`,
    changeTherapistDemo: `slot/changeTherapistInDemo`,
    getStartMeetingLink: `slot/getStartMeetingLink`,
  },
  appointment: {
    get: "appointments/get",
    add: "appointments/add",
  },
  logs: {
    get: "logs/get",
  },
  masterGames: {
    get: "masterGames/get",
  },
  files: {
    uploadFile: "file/upload",
    uploadDocument: "file/uploadDocument",
  },
  masterGoals: {
    get: "masterGoals/get",
    getV2: "masterGoals/getV2",
    add: "masterGoals/add",
  },
  masterActivities: {
    get: "masterActivities/get",
    getV2: "masterActivities/getV2",
    add: "masterActivities/add",
  },
  masterQuestion: {
    add: "masterQuestions/add",
    get: "masterQuestions/get",
    delete: "masterQuestions/delete",
    getV2: "masterQuestions/getV2",
  },
  userActivities: {
    get: "userActivities/v2/get",
    add: "userActivities/add",
    copy: "userActivities/copy",
  },
  tutorials: {
    get: "tutorials/get",
    add: "tutorials/add",
  },
  activityResponse: {
    get: "activityResponse/get",
    getIndividual: "activityResponse/get/individual",
    add: "activityResponse/add",
  },
  masterVideo: {
    get: "masterVideo/get",
    add: "masterVideo/add",
  },
  subscriptions: {
    get: "/subscriptions/get",
    subscribe: "/subscriptions/subscribe",
    getPlan: "/subscriptions/getPlans",
    getSinglePlan: "/subscriptions/getSinglePlan",
    add: "/subscriptions/add",
    updatePlan: "/subscriptions/updatePlans",
    deletePlan: "/subscriptions/deletePlan",
  },
  mediaManager: {
    add: "/mediaManager/add",
    // delete: (_id) =>`/mediaManager/delete?_id=${_id}`,
    delete: (id) => `/mediaManager/delete?_id=${id}`,
    get: (queryString) => `/mediaManager/get${queryString}`,
    usage: "/mediaManager/usage",
  },
  dashboardStats: "/reports/dashboardStats",
  planExpireReport: "/reports/userPlanExpire/list",
  slotCompleteReport: "/reports/slotsCompleted/list",
  slotBookReport: "/reports/slotsBooked/list",
  assessmentsReport: "/reports/assessments/list",
  partnerDashBoardStats: "/reports/partnerDashboardStats",
  demoGame: {
    get: "demoGame/getDemoGame",
    add: "demoGame/addDemoGame",
  },
  screeningTest: {
    getScreeningQuestion: "screeningTest/getQuestions",
    submitResponse: "screeningTest/submitResponse",
    responses: "screeningTest/responses",
  },
  commonQueries: {
    getDocumentsWithCount: "common/queries/getDocumentsWithCount",
    checkIfHostAvailable: "common/queries/checkIfHostAvailable",
    transferToCompanies: "common/queries/transferToCompanies",
  },
  demoGoalsRequest: {
    add: "demoGoalsRequest/add",
    get: "demoGoalsRequest/get",
  },
  bankTransfer: {
    add: "bankTransfer/add",
    get: "bankTransfer/get",
  },
  clientDocuments: {
    add: "clientDocuments/add",
    get: "clientDocuments/get",
    delete: "clientDocuments/delete",
  },
};

export { baseUrlWithSlash as apiBaseUrl };
export default apiUrls;
