import moment from "moment";
import React from "react";
const Dashboard = React.lazy(() => import("../views/dashboard/Dashboard"));
const CreatePaymentsLink = React.lazy(() => import("../pages/paymentsLink"));
const GetPaymentsLinks = React.lazy(() => import("../pages/paymentsLink/list"));
const AddSlots = React.lazy(() => import("../pages/slots/add/index"));
const UpdateSlots = React.lazy(() => import("../pages/slots/update/index"));
const getSlots = React.lazy(() => import("../pages/slots/list/index"));
const SlotDetails = React.lazy(() => import("../pages/slots/details/index"));
const MasterGames = React.lazy(() => import("../pages/masterGames"));

const UsersAdd = React.lazy(() => import("../pages/users/add/index"));
const UsersList = React.lazy(() => import("../pages/users/list/index"));
const MasterGoal = React.lazy(() => import("../pages/masterGoals/list"));
const AddMasterGoal = React.lazy(() => import("../pages/masterGoals/add"));

const AddUserGoals = React.lazy(() => import("../pages/UserGoals/add"));
const ListUserGoals = React.lazy(() => import("../pages/UserGoals/list"));
const ListMasterActivity = React.lazy(() =>
  import("../pages/masterActivity/list")
);
const AddMasterActivity = React.lazy(() =>
  import("../pages/masterActivity/add")
);

const TutorialList = React.lazy(() => import("../pages/tutorials/list"));

const AddTutorial = React.lazy(() => import("../pages/tutorial/add"));
const ListTutorials = React.lazy(() => import("../pages/tutorial/list"));
const AddMasterForm = React.lazy(() => import("../pages/masterQuestions/add"));
const MasterFormList = React.lazy(() =>
  import("../pages/masterQuestions/list")
);
const UsersGoal = React.lazy(() => import("../pages/UserGoals/list"));
const ListUserActivity = React.lazy(() =>
  import("../pages/masterActivity/list")
);
const AddMasterVideo = React.lazy(() => import("../pages/masterVideos/add"));
const ListMasterVideo = React.lazy(() => import("../pages/masterVideos/list"));
const AllAppointments = React.lazy(() => import("../pages/appointments"));
const AllSlotDetails = React.lazy(() => import("../pages/allSlotDetails"));
const CalendarView = React.lazy(() =>
  import("../views/dashboard/CalendarView")
);

const ClientDocumentsAdd = React.lazy(() =>
  import("../pages/client-documents/add")
);

const ClientDocumentsList = React.lazy(() =>
  import("../pages/client-documents/list")
);

const therapistRoutes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/my/appointments/list",
    name: "Assessments",
    component: AllAppointments,
  },
  {
    path: "/allSlotDetails/:slot_id",
    name: "All Slot Details",
    component: AllSlotDetails,
  },
  {
    path: "/masterQuestions/add",
    exact: true,
    name: "Add Question",
    component: AddMasterForm,
  },
  {
    path: "/masterQuestions/edit/:id",
    exact: true,
    name: "Edit Question",
    component: AddMasterForm,
  },
  {
    path: "/masterQuestions/list",
    exact: true,
    name: "Questions List",
    component: MasterFormList,
  },
  {
    path: "/my/clients/list",
    exact: true,
    name: "Clients List",
    component: UsersList,
  },
  {
    path: "/my/clients/view/:_id",
    exact: true,
    name: "View Client",
    component: UsersAdd,
  },

  {
    path: "/my/clients/add/:appointment_id",
    exact: true,
    name: "Add Client",
    component: UsersAdd,
  },
  {
    path: "/my/clients/add",
    exact: true,
    name: "Add Client",
    component: UsersAdd,
  },

  {
    path: "/my/slots/Add",
    exact: true,
    name: "Add Slot",
    component: AddSlots,
  },
  {
    path: "/my/slots/edit/:id",
    exact: true,
    name: "Edit Slot",
    component: UpdateSlots,
  },
  {
    path: `/my/slots/list`,
    exact: true,
    name: "Slots List",
    component: getSlots,
  },
  {
    path: "/my/slots/details/:slot_id/:booking_id?",
    exact: true,
    name: "Slot Details",
    component: SlotDetails,
  },
  // {
  //   path: "/master-games",
  //   exact: true,
  //   name: "Master Games",
  //   component: MasterGames,
  // },
  {
    path: "/masterActivity/list",
    exact: true,
    name: "View Master Activity",
    component: ListMasterActivity,
  },
  {
    path: "/masterActivity/add",
    exact: true,
    name: "Add Master Activity",
    component: AddMasterActivity,
  },
  {
    path: "/masterActivity/edit/:id",
    exact: true,
    name: "Edit Master Activity",
    component: AddMasterActivity,
  },
  {
    path: "/masterGoal/list",
    exact: true,
    name: "View Master Goal",
    component: MasterGoal,
  },

  {
    path: "/masterGoal/add",
    exact: true,
    name: "Add Master Goal",
    component: AddMasterGoal,
  },
  {
    path: "/masterGoal/edit/:id",
    exact: true,
    name: "Edit Master Goal",
    component: AddMasterGoal,
  },

  {
    path: "/UserGoals/add/:user_id",
    exact: true,
    name: "Add User Goals",
    component: AddUserGoals,
  },
  {
    path: "/UserGoals/add/:user_id",
    exact: true,
    name: "List User Goals",
    component: ListUserGoals,
  },
  {
    path: "/tutorials/add",
    exact: true,
    name: "Add Tutorial",
    component: AddTutorial,
  },
  {
    path: "/tutorials",
    exact: true,
    name: "Tutorials List",
    component: TutorialList,
  },
  {
    path: "/tutorials/edit/:id",
    exact: true,
    name: "Edit Tutorial",
    component: AddTutorial,
  },

  {
    path: "/masterVideo/add",
    exact: true,
    name: "Add MasterVideo",
    component: AddMasterVideo,
  },
  {
    path: "/masterVideo",
    exact: true,
    name: "MasterVideo List",
    component: ListMasterVideo,
  },
  {
    path: "/masterVideo/edit/:id",
    exact: true,
    name: "Edit MasterVideo",
    component: AddMasterVideo,
  },
  {
    path: "/tutorials/list",
    exact: true,
    name: "List Tutorials",
    component: ListTutorials,
  },
  {
    path: "/my_goals/:user_id",
    exact: true,
    name: "My Goals",
    component: UsersGoal,
  },
  {
    path: "/my_activities/:user_goal_id",
    exact: true,
    name: "My Activities",
    component: ListUserActivity,
  },
  {
    path: "/calendar-view",
    exact: true,
    name: "Calendar View",
    component: CalendarView,
  },
  {
    path: "/client-documents/add",
    exact: true,
    name: "Add Client Document",
    component: ClientDocumentsAdd,
  },
  {
    path: "/client-documents/list/:client_id?",
    exact: true,
    name: "Client Documents List",
    component: ClientDocumentsList,
  },
  {
    path: "/client-documents/edit/:id",
    exact: true,
    name: "Edit Client Document",
    component: ClientDocumentsAdd,
  },
];

export default therapistRoutes;
