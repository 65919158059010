import React from "react";
const Dashboard = React.lazy(() => import("../views/dashboard/Dashboard"));
const LeadsAdd = React.lazy(() => import("../pages/leads/add/index"));
const LeadsList = React.lazy(() => import("../pages/leads/list/index"));

const partnerRoutes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/leads/list",
    exact: true,
    name: "Leads List",
    component: LeadsList,
  },
  {
    path: "/leads/view/:_id",
    exact: true,
    name: "View Lead",
    component: LeadsAdd,
  },
  {
    path: "/leads/add",
    exact: true,
    name: "Add Lead",
    component: LeadsAdd,
  },
];

export default partnerRoutes;
