import React from "react";
import { CFormGroup, CInput, CLabel } from "@coreui/react";

const SimpleInput = ({
  id = "",
  title = "",
  placeholder = "",
  value = "",
  type = "text",
  className = "",
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  error,
  required = false,
  disabled = false,
  sideText,
  isEditable = true,
  minTime,
  maxTime,
  style = {},
  isViewAble = true,
}) => {
  console.log(error, "errrrr");
  return (
    <>
      {isViewAble === true && title && (
        <CLabel htmlFor={id}>
          {title}
          {required ? <span style={{ color: "red" }}> *</span> : null}
        </CLabel>
      )}

      {isEditable ? (
        <CInput
          id={id}
          type={type}
          className={className}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          min={minTime}
          max={maxTime}
          {...(maxTime || minTime ? required : null)}
          style={{
            borderColor: error ? "#e55353" : "",
            paddingBlock: 25,
            ...style,
          }}
          disabled={disabled}
        />
      ) : (
        isViewAble && (
          <div style={{ fontSize: 16.5, fontWeight: 500 }}>{value || "-"}</div>
        )
      )}

      {error ? (
        <CLabel htmlFor={id} style={{ color: "#e55353", marginBottom: 0 }}>
          {error}
        </CLabel>
      ) : null}
    </>
  );
};

export default SimpleInput;
