import { isArray } from "lodash";
import { useState } from "react";
import { useDispatch } from "react-redux";
import apiClient from "src/apis/api-client";
import { SET_LOADER } from "src/redux/actions";

const useMutation = (noLoading = false) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [error, setError] = useState();

  const getData = async (params) => {
    const {
      url,
      postData = {},
      onSuccess = () => {},
      onFail = () => {},
      giveFirst = false,
      isGetApi = false,
    } = params;
    if (!noLoading) {
      dispatch({ type: SET_LOADER, payload: true });
    }
    let config = {
      headers: {
        user: postData?.user_id
          ? postData?.user_id
          : localStorage.getItem("userId"),
        language: localStorage.getItem("i18nextLng") || "en-US",
      },
    };
    try {
      let { data: apiData = {} } = isGetApi
        ? await apiClient.get(url, config)
        : await apiClient.post(url, postData, config);
      console.log(apiData, "apiData1");
      apiData = isArray(apiData?.data?.data) ? apiData?.data : apiData;
      console.log(apiData, "apiData2");
      setData(giveFirst ? apiData?.data[0] : apiData?.data);
      if (apiData?.status === "success") {
        await onSuccess(giveFirst ? apiData?.data[0] : apiData?.data);
      } else {
        onFail(apiData?.data);
      }
      return giveFirst ? apiData?.data[0] : apiData?.data;
    } catch (err) {
      onFail(err);
      setError(err);
    } finally {
      if (!noLoading) {
        dispatch({ type: SET_LOADER, payload: false });
      }
    }
  };

  return {
    getData,
    data,
    setData,
    error,
    setError,
  };
};

export default useMutation;
