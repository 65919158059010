import React from "react";
import ResetPassword from "src/pages/auth/resetPassword";
const Login = React.lazy(() => import("../pages/auth/login/index"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const DemoGame = React.lazy(() => import("../pages/DemoGame/list/index"));

const authRoutes = [
  { path: "/login", name: "Login Page", component: Login },
  {
    path: "/forgetPassword",
    name: "Forget Password",
    component: ForgetPassword,
  },

  {
    path: "/password/:type",
    name: "Reset Password",
    component: ResetPassword,
  },
  // {
  //   path: "/demoGoals",
  //   name: "Demo - Goals",
  //   component: DemoGame,
  // },
];

export default authRoutes;
